var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "section-note",
    class: [{
      'border-theme': !_vm.config.showBackgroundColor && !_vm.isBasicType
    }, {
      'border-0 bg-theme rounded-lg': _vm.isBasicType
    }]
  }, [_c('div', {
    staticStyle: {
      "flex": "1",
      "position": "relative"
    }
  }, [_vm.config.locale === 'vi' ? _c('div', [_c('quill-editor', {
    attrs: {
      "options": _vm.editorOption
    },
    model: {
      value: _vm.config.note,
      callback: function callback($$v) {
        _vm.$set(_vm.config, "note", $$v);
      },
      expression: "config.note"
    }
  })], 1) : _vm.config.locale === 'en' ? _c('div', [_c('quill-editor', {
    attrs: {
      "options": _vm.editorOption
    },
    model: {
      value: _vm.config.noteEn,
      callback: function callback($$v) {
        _vm.$set(_vm.config, "noteEn", $$v);
      },
      expression: "config.noteEn"
    }
  })], 1) : _vm._e(), _vm.isConfig && _vm.config.note ? _c('b-button', {
    directives: [{
      name: "b-tooltip",
      rawName: "v-b-tooltip.hover.v-danger.window",
      modifiers: {
        "hover": true,
        "v-danger": true,
        "window": true
      }
    }],
    staticClass: "p-1 rounded-circle",
    staticStyle: {
      "position": "absolute",
      "right": "-20px",
      "top": "0"
    },
    attrs: {
      "title": "Đặt lại ghi chú về mặc định",
      "variant": "flat-danger"
    },
    on: {
      "click": _vm.handleResetNote
    }
  }, [_c('feather-icon', {
    attrs: {
      "icon": "RotateCwIcon",
      "size": "20"
    }
  })], 1) : _vm._e()], 1), _c('div', {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.config.showPayment,
      expression: "config.showPayment"
    }],
    staticClass: "d-flex-center flex-column gap-4px",
    staticStyle: {
      "margin-left": "20px"
    }
  }, [_c('div', {
    staticClass: "font-weight-bolder color-theme text-nowrap"
  }, [_vm._v(" " + _vm._s(_vm.$t('ticket.paymentInfo', _vm.config.locale)) + " ")]), _c('div', {
    staticClass: "border"
  }, [_c('b-img', {
    staticStyle: {
      "width": "100px"
    },
    attrs: {
      "src": _vm.qrCodeAvatar
    }
  })], 1), _vm.defaultBankAccount ? _c('div', {
    staticClass: "font-weight-bolder",
    staticStyle: {
      "text-align": "center"
    }
  }, [_c('div', [_vm._v(_vm._s(_vm.defaultBankAccount.bankAccountName))]), _c('div', [_vm._v(_vm._s(_vm.defaultBankAccount.bankAccountNo))]), _c('div', {
    staticClass: "d-flex-center justify-content-start"
  }, [_c('BImg', {
    attrs: {
      "src": _vm.bankAvatar,
      "width": "70"
    }
  }), _c('div', [_vm._v(_vm._s(_vm.resolveBankCode(_vm.defaultBankAccount.bankCode).bankName))])], 1)]) : _vm._e()])]);
}
var staticRenderFns = []

export { render, staticRenderFns }