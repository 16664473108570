<!-- eslint-disable vue/no-mutating-props -->
<template lang="">
  <div
    v-show="config.showAgency"
    class="section-agency"
  >
    <div style="flex: 1; position: relative;">
      <div v-if="config.locale === 'vi'">
        <quill-editor
          v-if="config.useQuillEditor"
          v-model="config.agencyInfo"
          :options="editorOption"
          class="fit"
        />
        <div
          v-else-if="config.agencyInfo"
          contenteditable="true"
          class="fit"
          @input="config.agencyInfo = $event.target.innerHTML"
        >
          <div
            v-once
            class="fit"
            v-html="config.agencyInfo"
          />
        </div>
        <div
          v-else
          class="fit"
          contenteditable="true"
          @input="config.agencyInfo = $event.target.innerHTML"
        >
          <div v-if="agencyInfo">
            <div>
              <b>{{ agencyInfo.agencyName }}</b>
            </div>
            <div>
              Địa chỉ: {{ agencyInfo.agencyAddress }}
            </div>
            <div>
              Hotline: {{ agencyInfo.ownerPhoneNumber }}
            </div>
          </div>
        </div>
      </div>
      <div v-else-if="config.locale === 'en'">
        <quill-editor
          v-if="config.useQuillEditor"
          v-model="config.agencyInfoEn"
          :options="editorOption"
          class="fit"
        />
        <div
          v-else-if="config.agencyInfoEn"
          contenteditable="true"
          class="fit"
          @input="config.agencyInfoEn = $event.target.innerHTML"
        >
          <div
            v-once
            class="fit"
            v-html="config.agencyInfoEn"
          />
        </div>
        <div
          v-else
          contenteditable="true"
          @input="config.agencyInfoEn = $event.target.innerHTML"
        >
          <div v-if="config.agencyInfoEn">
            <div>
              <b>{{ config.agencyInfoEn?.agencyName }}</b>
            </div>
            <div>
              Address: {{ config.agencyInfoEn?.agencyAddress }}
            </div>
            <div>
              Hotline: {{ config.agencyInfoEn?.ownerPhoneNumber }}
            </div>
          </div>
        </div>
      </div>

      <b-button
        v-if="isConfig && (config.agencyInfo || config.agencyInfoEn)"
        v-b-tooltip.hover.v-danger.window
        style="position: absolute; right: 0; top: 0;"
        title="Đặt lại thông tin đại lý về mặc định"
        variant="flat-danger"
        class="p-1 rounded-circle"
        @click="handleResetAgencyInfo"
      >
        <feather-icon
          icon="RotateCwIcon"
          size="20"
        />
      </b-button>
    </div>
    <b-img
      v-if="agencyAvatar && config.showAgencyLogo"
      :src="agencyAvatar"
      style="height: 80px;"
    />
  </div>
</template>
<script>
import { BButton, BImg } from 'bootstrap-vue'
import { quillEditor } from 'vue-quill-editor'

export default {
  components: {
    quillEditor,
    BButton,
    BImg,
  },
  props: {
    config: {
      type: Object,
      default: () => ({}),
    },
    isConfig: {
      type: Boolean,
      default: false,
    },
    agencyAvatar: {
      type: [String, null],
      default: null,
    },
    editorOption: {
      type: Object,
      default: () => ({}),
    },
    agencyInfo: {
      type: Object,
      default: () => ({}),
    },
    handleResetAgencyInfo: {
      type: Function,
      default: () => {},
    },
  },
}
</script>
<style lang="">

</style>
