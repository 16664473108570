var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "e-ticket-train d-flex flex-column bg-white mx-auto",
    class: [_vm.printSize, "theme-".concat(_vm.currentTheme), {
      'border border-theme': _vm.frontendI18n
    }, {
      'showBackgroundColor': _vm.config.showBackgroundColor
    }]
  }, [_c('TrainETicketAgencyInfo', {
    attrs: {
      "config": _vm.config,
      "is-config": _vm.isConfig,
      "agencyAvatar": _vm.agencyAvatar,
      "editorOption": _vm.editorOption,
      "agencyInfo": _vm.agencyInfo,
      "handleResetAgencyInfo": _vm.handleResetAgencyInfo
    }
  }), _c('TrainETicketTitle', {
    attrs: {
      "frontendI18n": _vm.frontendI18n,
      "config": _vm.config
    }
  }), _c('TrainETicketPassengerInfo', {
    attrs: {
      "frontendI18n": _vm.frontendI18n,
      "config": _vm.config,
      "currentBgColor": _vm.currentBgColor,
      "trips": _vm.trips,
      "passengerTargetList": _vm.passengerTargetList,
      "addPax": _vm.addPax,
      "removePax": _vm.removePax
    }
  }), _c('TrainETicketPriceInfo', {
    attrs: {
      "config": _vm.config,
      "currentBgColor": _vm.currentBgColor,
      "ticketData": _vm.ticketData,
      "sumAll": _vm.sumAll,
      "trips": _vm.trips,
      "passengerTargetList": _vm.passengerTargetList,
      "handleChangePax": _vm.handleChangePax,
      "handleChangeSumAll": _vm.handleChangeSumAll,
      "handleChangeBookingTotal": _vm.handleChangeBookingTotal
    }
  }), _c('TrainETicketNote', {
    attrs: {
      "config": _vm.config,
      "editorOption": _vm.editorOption,
      "isConfig": _vm.isConfig,
      "qrCodeAvatar": _vm.qrCodeAvatar,
      "defaultBankAccount": _vm.defaultBankAccount,
      "resolveBankCode": _vm.resolveBankCode,
      "bankAvatar": _vm.bankAvatar,
      "handleResetNote": _vm.handleResetNote
    }
  })], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }