var render = function () {
  var _vm$config$agencyInfo, _vm$config$agencyInfo2, _vm$config$agencyInfo3;
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.config.showAgency,
      expression: "config.showAgency"
    }],
    staticClass: "section-agency"
  }, [_c('div', {
    staticStyle: {
      "flex": "1",
      "position": "relative"
    }
  }, [_vm.config.locale === 'vi' ? _c('div', [_vm.config.useQuillEditor ? _c('quill-editor', {
    attrs: {
      "options": _vm.editorOption
    },
    model: {
      value: _vm.config.agencyInfo,
      callback: function callback($$v) {
        _vm.$set(_vm.config, "agencyInfo", $$v);
      },
      expression: "config.agencyInfo"
    }
  }) : _vm.config.agencyInfo ? _c('div', {
    attrs: {
      "contenteditable": ""
    },
    on: {
      "input": function input($event) {
        _vm.config.agencyInfo = $event.target.innerHTML;
      }
    }
  }, [_vm._m(0)]) : _c('div', {
    attrs: {
      "contenteditable": ""
    },
    on: {
      "input": function input($event) {
        _vm.config.agencyInfo = $event.target.innerHTML;
      }
    }
  }, [_vm.agencyInfo ? _c('div', [_c('div', [_c('b', [_vm._v(_vm._s(_vm.agencyInfo.agencyName))])]), _c('div', [_vm._v(" Địa chỉ: " + _vm._s(_vm.agencyInfo.agencyAddress) + " ")]), _c('div', [_vm._v(" Hotline: " + _vm._s(_vm.agencyInfo.ownerPhoneNumber) + " ")])]) : _vm._e()])], 1) : _vm.config.locale === 'en' ? _c('div', [_vm.config.useQuillEditor ? _c('quill-editor', {
    attrs: {
      "options": _vm.editorOption
    },
    model: {
      value: _vm.config.agencyInfoEn,
      callback: function callback($$v) {
        _vm.$set(_vm.config, "agencyInfoEn", $$v);
      },
      expression: "config.agencyInfoEn"
    }
  }) : _vm.config.agencyInfoEn ? _c('div', {
    attrs: {
      "contenteditable": ""
    },
    on: {
      "input": function input($event) {
        _vm.config.agencyInfoEn = $event.target.innerHTML;
      }
    }
  }, [_vm._m(1)]) : _c('div', {
    attrs: {
      "contenteditable": ""
    },
    on: {
      "input": function input($event) {
        _vm.config.agencyInfoEn = $event.target.innerHTML;
      }
    }
  }, [_vm.config.agencyInfoEn ? _c('div', [_c('div', [_c('b', [_vm._v(_vm._s((_vm$config$agencyInfo = _vm.config.agencyInfoEn) === null || _vm$config$agencyInfo === void 0 ? void 0 : _vm$config$agencyInfo.agencyName))])]), _c('div', [_vm._v(" Address: " + _vm._s((_vm$config$agencyInfo2 = _vm.config.agencyInfoEn) === null || _vm$config$agencyInfo2 === void 0 ? void 0 : _vm$config$agencyInfo2.agencyAddress) + " ")]), _c('div', [_vm._v(" Hotline: " + _vm._s((_vm$config$agencyInfo3 = _vm.config.agencyInfoEn) === null || _vm$config$agencyInfo3 === void 0 ? void 0 : _vm$config$agencyInfo3.ownerPhoneNumber) + " ")])]) : _vm._e()])], 1) : _vm._e(), _vm.isConfig && (_vm.config.agencyInfo || _vm.config.agencyInfoEn) ? _c('b-button', {
    directives: [{
      name: "b-tooltip",
      rawName: "v-b-tooltip.hover.v-danger.window",
      modifiers: {
        "hover": true,
        "v-danger": true,
        "window": true
      }
    }],
    staticClass: "p-1 rounded-circle",
    staticStyle: {
      "position": "absolute",
      "right": "0",
      "top": "0"
    },
    attrs: {
      "title": "Đặt lại thông tin đại lý về mặc định",
      "variant": "flat-danger"
    },
    on: {
      "click": _vm.handleResetAgencyInfo
    }
  }, [_c('feather-icon', {
    attrs: {
      "icon": "RotateCwIcon",
      "size": "20"
    }
  })], 1) : _vm._e()], 1), _vm.agencyAvatar && _vm.config.showAgencyLogo ? _c('b-img', {
    staticStyle: {
      "height": "80px"
    },
    attrs: {
      "src": _vm.agencyAvatar
    }
  }) : _vm._e()], 1);
}
var staticRenderFns = [function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    domProps: {
      "innerHTML": _vm._s(_vm.config.agencyInfo)
    }
  });
} /* staticRenderFnsSpliter */, function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    domProps: {
      "innerHTML": _vm._s(_vm.config.agencyInfoEn)
    }
  });
}]

export { render, staticRenderFns }