var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "d-flex-center justify-content-between text-20px fw-700 px-20px"
  }, [_c('div', {
    staticClass: "color-theme text-uppercase",
    attrs: {
      "contenteditable": "true"
    }
  }, [_vm._v(" " + _vm._s(_vm.$t('ticket.eTicket', _vm.config.locale)) + " ")]), _c('div', {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.config.showTimeHold,
      expression: "config.showTimeHold"
    }],
    staticClass: "text-red text-14px font-weight-bolder"
  }, [_vm.frontendI18n ? [_vm._v(" " + _vm._s(_vm.$t('ticket.prepaymentNote', _vm.config.locale)) + " ")] : !_vm.config.status.toUpperCase().includes('PAID') ? [_c('div', {
    attrs: {
      "contenteditable": "true"
    },
    on: {
      "input": function input($event) {
        _vm.config.timeHoldNote = $event.target.textContent.trim();
      }
    }
  }, [_vm.timeHold ? _c('span', [_vm._v(" " + _vm._s(_vm.$t('ticket.payBefore', _vm.config.locale)) + ": " + _vm._s(_vm.timeHold) + " ")]) : _vm._e()])] : _vm._e()], 2)]);
}
var staticRenderFns = []

export { render, staticRenderFns }