<template lang="">
  <div class="rounded-lg border-theme border-2">
    <div class="bg-theme-dark d-flex justify-content-start align-items-center gap-2 px-1 py-50 text-white">
      <IAmIcon
        icon="tick"
        size="18"
      />
      <div
        class="fw-700 text-20px"
        contenteditable="true"
      >
        {{ $t('ticket.flightsInfo.bookingID', config.locale) }}:
      </div>
    </div>
    <BAlert
      :show="!flights.length"
      variant="warning"
      class="px-2 py-1 mb-0"
    >
      {{ $t('reservation.flightHasBeenCancelled') }}.
    </BAlert>
    <div
      v-for="(flight, index) in flights"
      :key="index"
      class="section-flight d-flex flex-column text-gray bg-theme position-relative rounded-last-child overflow-hidden"
      :style="`${config.showBackgroundColor ? `background-color: ${currentBgColor} !important; ` : ''}border: none;`"
    >
      <div
        class="d-flex gap-4px position-absolute"
        style="top: 8px; right: 8px; z-index: 10;"
      >
        <b-button
          class="pax-button"
          variant="outline-danger"
          style="padding: 4px;"
          @click="removeFlight(index)"
        >
          <feather-icon
            icon="MinusIcon"
            size="16"
          />
        </b-button>
        <b-button
          class="pax-button"
          variant="outline-success"
          style="padding: 4px;"
          @click="addFlight(index, flight)"
        >
          <feather-icon
            icon="PlusIcon"
            size="16"
          />
        </b-button>
      </div>

      <div class="d-flex-center gap-16px">
        <div
          class="segment-content-l flex-0 d-flex justify-content-end fit-content"
          style="min-width: unset; max-width: 600px"
        >
          <div
            class="position-relative overflow-hidden border-theme rounded-8px"
            style="border-width: 2px !important;"
          >
            <div class="segment-booking-code d-flex-center align-self-stretch color-theme bg-white fit-content px-3 py-50">
              <div
                v-show="config.showStatus"
                id="electronic-ticket-status"
                class="payment-status position-absolute cursor-pointer"
                :class="flight.bookingStatus
                  ? (flight.bookingStatus.includes('PAID')
                    ? 'bg-current' : (flight.bookingStatus === 'HOLD'
                      ? 'border-theme' : 'bg-danger border-danger') )
                  : config.status.toUpperCase().includes('PAID') ? 'bg-current' : 'border-theme'"
                style="top: 24px"
                @click="toggleConfigStatus(flight)"
              >
                <span
                  :class="flight.bookingStatus
                    ? (flight.bookingStatus.includes('PAID')
                      ? 'text-white' : (flight.bookingStatus === 'HOLD'
                        ? 'color-theme' : ' text-white') )
                    : config.status.toUpperCase().includes('PAID')
                      ? 'text-white'
                      : (config.status === 'EXPIRED' ? 'text-red' : 'color-theme')"
                >
                  {{ $t(`reservation.${flight.bookingStatus || config.status}`, config.locale) }}
                </span>
              </div>
              <b-tooltip
                class="cursor-pointer"
                target="electronic-ticket-status"
                triggers="hover"
                placement="top"
                boundary="viewport"
                variant="danger"
              >
                {{ $t('ticket.clickToChangeStatus') }}
                <span class="fw-700">
                  {{
                    config.status.toUpperCase().includes('PAID')
                      ? $t('ticket.statusHold', config.locale)
                      : config.status.toUpperCase() === 'HOLD'
                        ? $t('ticket.statusCancel', config.locale)
                        : $t('ticket.statusPaid', config.locale)
                  }}
                </span>
              </b-tooltip>

              <div
                class="fw-700 text-center text-40px text-uppercase text-nowrap"
                style="padding-right: 36px"
                contenteditable="true"
                :style="`${resolveBorderColorByAirline(flight.source)}`"
              >
                {{ flight.bookingCode }}
                <span v-if="resolveAirPnr(flight.supplierPNRs, flight.airline)">/{{ resolveAirPnr(flight.supplierPNRs, flight.source) }}</span>
              </div>
            </div>
          </div>
        </div>

        <div
          v-for="(segment, sIndex) in uniqBy(flight.segments, 'airline')"
          :key="sIndex"
        >
          <div
            :key="`segment-wrapper-${index}-${sIndex}`"
            class="segment-content d-flex-center"
          >
            <div class=" d-flex flex-column gap-8px fw-700 flex-0 rounded-8px">
              <div class="airline d-flex-center gap-16px">
                <div v-if="airlinesLogoLong.includes(segment.operating || segment.airline)">
                  <IAmLogoAirline
                    :airline="segment.operating === 'BL' ? segment.operating : segment.airline"
                    :long-logo="true"
                    :hide-tooltip="true"
                    size="lg"
                    blob
                  />
                </div>
                <div
                  v-else
                  class="d-flex-center gap-1"
                >
                  <IAmLogoAirline
                    :airline="segment.operating === 'BL' ? segment.operating : segment.airline"
                    :hide-tooltip="true"
                    :height="50"
                    blob
                  />
                  <div
                    class="text-24px font-weight-bolder text-nowrap"
                    contenteditable
                  >
                    {{ getAirlineNameByCode(segment.operating === 'BL' ? segment.operating : segment.airline) }}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <hr
        v-if="index !== (flights.length - 1)"
        class="border-bottom-dashed"
      >
    </div>
  </div>
</template>
<script>
import { BAlert, BTooltip, BButton } from 'bootstrap-vue'
import uniqBy from 'lodash/uniqBy'

import { airlinesLogoLong } from '@/constants/reservation'
import store from '@/store'

import { resolveBorderColorByAirline } from '@core/utils/filter'

import { resolveAirPnr } from '@reservation/useReservationHandle'

export default {
  components: {
    BButton,
    BAlert,
    BTooltip,
    IAmLogoAirline: () => import('@/components/IAmLogoAirline.vue'),
  },
  props: {
    config: {
      type: Object,
      default: () => ({}),
    },
    flights: {
      type: Array,
      default: () => [],
    },
    currentBgColor: {
      type: [String, null],
      default: null,
    },
    addFlight: {
      type: Function,
      default: () => {},
    },
    removeFlight: {
      type: Function,
      default: () => {},
    },
  },
  setup(props) {
    function getAirlineNameByCode(code) {
      return store.getters['globalConfig/getAirlineNameByCode'](code)
    }

    function toggleConfigStatus(flight) {
      const statusOptions = ['PAID', 'HOLD', 'CANCEL']
      const indexBookingStatus = statusOptions.findIndex(i => i === flight.bookingStatus || i === props.config.status)
      flight.bookingStatus = indexBookingStatus + 1 === 3 ? statusOptions[0] : statusOptions[indexBookingStatus + 1]
      // eslint-disable-next-line vue/no-mutating-props
      props.config.status = indexBookingStatus + 1 === 3 ? statusOptions[0] : statusOptions[indexBookingStatus + 1]
    }

    return {
      resolveAirPnr,
      airlinesLogoLong,
      toggleConfigStatus,
      getAirlineNameByCode,
      resolveBorderColorByAirline,
      uniqBy,
    }
  },
}
</script>
<style lang="scss" scoped>
  .border-bottom-dashed {
    display: block;
    height: 0.5px;
    width: 80%;
    margin: 0 auto;
    border: unset;
    border-bottom: 2px dashed #ccc;
  }
  .rounded-last-child {
    border-radius: 0;
    &:last-child{
      border-radius: 0 0 8px 8px;
    }
  }

</style>
