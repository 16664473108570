var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "rounded-lg border-theme border-2"
  }, [_c('div', {
    staticClass: "bg-theme-dark d-flex justify-content-start align-items-center gap-2 px-1 py-50 text-white"
  }, [_c('IAmIcon', {
    attrs: {
      "icon": "tick",
      "size": "18"
    }
  }), _c('div', {
    staticClass: "fw-700 text-20px",
    attrs: {
      "contenteditable": "true"
    }
  }, [_vm._v(" " + _vm._s(_vm.$t('ticket.flightsInfo.bookingID', _vm.config.locale)) + ": ")])], 1), _c('BAlert', {
    staticClass: "px-2 py-1 mb-0",
    attrs: {
      "show": !_vm.flights.length,
      "variant": "warning"
    }
  }, [_vm._v(" " + _vm._s(_vm.$t('reservation.flightHasBeenCancelled')) + ". ")]), _vm._l(_vm.flights, function (flight, index) {
    return _c('div', {
      key: index,
      staticClass: "section-flight d-flex flex-column text-gray bg-theme position-relative rounded-last-child overflow-hidden",
      style: "".concat(_vm.config.showBackgroundColor ? "background-color: ".concat(_vm.currentBgColor, " !important; ") : '', "border: none;")
    }, [_c('div', {
      staticClass: "d-flex gap-4px position-absolute",
      staticStyle: {
        "top": "8px",
        "right": "8px",
        "z-index": "10"
      }
    }, [_c('b-button', {
      staticClass: "pax-button",
      staticStyle: {
        "padding": "4px"
      },
      attrs: {
        "variant": "outline-danger"
      },
      on: {
        "click": function click($event) {
          return _vm.removeFlight(index);
        }
      }
    }, [_c('feather-icon', {
      attrs: {
        "icon": "MinusIcon",
        "size": "16"
      }
    })], 1), _c('b-button', {
      staticClass: "pax-button",
      staticStyle: {
        "padding": "4px"
      },
      attrs: {
        "variant": "outline-success"
      },
      on: {
        "click": function click($event) {
          return _vm.addFlight(index, flight);
        }
      }
    }, [_c('feather-icon', {
      attrs: {
        "icon": "PlusIcon",
        "size": "16"
      }
    })], 1)], 1), _c('div', {
      staticClass: "d-flex-center gap-16px"
    }, [_c('div', {
      staticClass: "segment-content-l flex-0 d-flex justify-content-end fit-content",
      staticStyle: {
        "min-width": "unset",
        "max-width": "600px"
      }
    }, [_c('div', {
      staticClass: "position-relative overflow-hidden border-theme rounded-8px",
      staticStyle: {
        "border-width": "2px !important"
      }
    }, [_c('div', {
      staticClass: "segment-booking-code d-flex-center align-self-stretch color-theme bg-white fit-content px-3 py-50"
    }, [_c('div', {
      directives: [{
        name: "show",
        rawName: "v-show",
        value: _vm.config.showStatus,
        expression: "config.showStatus"
      }],
      staticClass: "payment-status position-absolute cursor-pointer",
      class: flight.bookingStatus ? flight.bookingStatus.includes('PAID') ? 'bg-current' : flight.bookingStatus === 'HOLD' ? 'border-theme' : 'bg-danger border-danger' : _vm.config.status.toUpperCase().includes('PAID') ? 'bg-current' : 'border-theme',
      staticStyle: {
        "top": "24px"
      },
      attrs: {
        "id": "electronic-ticket-status"
      },
      on: {
        "click": function click($event) {
          return _vm.toggleConfigStatus(flight);
        }
      }
    }, [_c('span', {
      class: flight.bookingStatus ? flight.bookingStatus.includes('PAID') ? 'text-white' : flight.bookingStatus === 'HOLD' ? 'color-theme' : ' text-white' : _vm.config.status.toUpperCase().includes('PAID') ? 'text-white' : _vm.config.status === 'EXPIRED' ? 'text-red' : 'color-theme'
    }, [_vm._v(" " + _vm._s(_vm.$t("reservation.".concat(flight.bookingStatus || _vm.config.status), _vm.config.locale)) + " ")])]), _c('b-tooltip', {
      staticClass: "cursor-pointer",
      attrs: {
        "target": "electronic-ticket-status",
        "triggers": "hover",
        "placement": "top",
        "boundary": "viewport",
        "variant": "danger"
      }
    }, [_vm._v(" " + _vm._s(_vm.$t('ticket.clickToChangeStatus')) + " "), _c('span', {
      staticClass: "fw-700"
    }, [_vm._v(" " + _vm._s(_vm.config.status.toUpperCase().includes('PAID') ? _vm.$t('ticket.statusHold', _vm.config.locale) : _vm.config.status.toUpperCase() === 'HOLD' ? _vm.$t('ticket.statusCancel', _vm.config.locale) : _vm.$t('ticket.statusPaid', _vm.config.locale)) + " ")])]), _c('div', {
      staticClass: "fw-700 text-center text-40px text-uppercase text-nowrap",
      staticStyle: {
        "padding-right": "36px"
      },
      style: "".concat(_vm.resolveBorderColorByAirline(flight.source)),
      attrs: {
        "contenteditable": "true"
      }
    }, [_vm._v(" " + _vm._s(flight.bookingCode) + " "), _vm.resolveAirPnr(flight.supplierPNRs, flight.airline) ? _c('span', [_vm._v("/" + _vm._s(_vm.resolveAirPnr(flight.supplierPNRs, flight.source)))]) : _vm._e()])], 1)])]), _vm._l(_vm.uniqBy(flight.segments, 'airline'), function (segment, sIndex) {
      return _c('div', {
        key: sIndex
      }, [_c('div', {
        key: "segment-wrapper-".concat(index, "-").concat(sIndex),
        staticClass: "segment-content d-flex-center"
      }, [_c('div', {
        staticClass: " d-flex flex-column gap-8px fw-700 flex-0 rounded-8px"
      }, [_c('div', {
        staticClass: "airline d-flex-center gap-16px"
      }, [_vm.airlinesLogoLong.includes(segment.operating || segment.airline) ? _c('div', [_c('IAmLogoAirline', {
        attrs: {
          "airline": segment.operating === 'BL' ? segment.operating : segment.airline,
          "long-logo": true,
          "hide-tooltip": true,
          "size": "lg",
          "blob": ""
        }
      })], 1) : _c('div', {
        staticClass: "d-flex-center gap-1"
      }, [_c('IAmLogoAirline', {
        attrs: {
          "airline": segment.operating === 'BL' ? segment.operating : segment.airline,
          "hide-tooltip": true,
          "height": 50,
          "blob": ""
        }
      }), _c('div', {
        staticClass: "text-24px font-weight-bolder text-nowrap",
        attrs: {
          "contenteditable": ""
        }
      }, [_vm._v(" " + _vm._s(_vm.getAirlineNameByCode(segment.operating === 'BL' ? segment.operating : segment.airline)) + " ")])], 1)])])])]);
    })], 2), index !== _vm.flights.length - 1 ? _c('hr', {
      staticClass: "border-bottom-dashed"
    }) : _vm._e()]);
  })], 2);
}
var staticRenderFns = []

export { render, staticRenderFns }