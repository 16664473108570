import store from '@/store'

export function getBaggagesByBookingClass(airline, bookingClass, groupClass) {
  return store.getters['globalConfig/getBaggagesByBookingClass'](
    airline,
    bookingClass,
    groupClass,
  )
}

export function getSegmentAirlineToParseBaggages(segment) {
  /*
  if (segment.operating) return segment.operating
  if (segment.flight.startsWith('VN-6')) return 'BL'

  return segment.airline
  */
  return segment.operating || segment.airline
}

export function getGroupClass(segment) {
  const airline = getSegmentAirlineToParseBaggages(segment)
  const { groupClass } = getBaggagesByBookingClass(
    airline,
    segment.bookingClass,
    segment.groupClass,
  )
  const groupClassParser = [
    { key: 'BF', name: 'BUSINESS FLEX' },
    { key: 'BC', name: 'BUSINESS CLASSIC' },
    { key: 'DF', name: 'PREMIUM ECONOMY FLEX' },
    { key: 'DC', name: 'PREMIUM ECONOMY CLASSIC' },
    { key: 'EF', name: 'ECONOMY FLEX' },
    { key: 'EC', name: 'ECONOMY CLASSIC' },
    { key: 'EL', name: 'ECONOMY LITE' },
    { key: 'EP', name: 'ECONOMY SUPER LITE' },
  ]
  const fullGroupClass = groupClass ? (groupClassParser.find(item => item.key === groupClass)?.name ?? groupClass.toUpperCase()) : ''

  const result = fullGroupClass.includes('FIRST')
    ? 'FIRST'
    : fullGroupClass.includes('PREMIUM')
      ? 'PREMIUM'
      : fullGroupClass.includes('BUSINESS') || fullGroupClass.includes('SKYBOSS')
        ? 'BUSINESS'
        : ['ECO', 'DELUXE', 'DLX'].some(textClass => fullGroupClass.includes(textClass))
          ? 'ECONOMY'
          : null
  return result
}

export function getHandBaggage(segment) {
  const airline = getSegmentAirlineToParseBaggages(segment)
  const bookingClass = segment.bookingClass
  const groupClass = segment.groupClass
  const { boardingBaggage } = getBaggagesByBookingClass(
    airline,
    bookingClass,
    groupClass,
  )
  return boardingBaggage
}

export function getFreeBaggage(segment, forcedLocale = this.$i18n.locale) {
  const airline = getSegmentAirlineToParseBaggages(segment)
  const bookingClass = segment.bookingClass
  const groupClass = segment.groupClass
  const { checkinBaggage } = getBaggagesByBookingClass(
    airline,
    bookingClass,
    groupClass,
  )
  if (checkinBaggage) return checkinBaggage

  // case: not applicate free baggage
  // trường hợp: không áp dụng hành lý miễn cước
  if (['FEE', 'Có phí', 'NONE'].includes(checkinBaggage)) {
    return this.$t('ticket.na', forcedLocale) // 'N/A' || 'Không áp dụng'
  }

  // case: free baggage includes golf
  // trường hợp: hành lý miễn phí bao gồm golf
  // example: checkinBaggage === '40KG + GOLF'
  if (checkinBaggage.includes(' + GOLF')) {
    return checkinBaggage.replaceAll('KG', 'kg').replaceAll('GOLF', 'golf')
  }

  // example: checkinBaggage === '1PC (32KG)'
  if (checkinBaggage.includes('PC ') || checkinBaggage.includes('KIỆN ')) {
    return checkinBaggage
      .replaceAll('KG', 'kg')
      .replaceAll('KIỆN', 'PC')
      .replaceAll('PC', ` ${this.$t('ticket.pack', forcedLocale)}`)
  }

  return checkinBaggage.replaceAll('KG', 'kg')
}
