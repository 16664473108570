var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "d-flex flex-column gap-2"
  }, _vm._l(_vm.trips, function (trip) {
    var _trip$tripInfos$0$dep, _trip$tripInfos, _trip$tripInfos$0$arr, _trip$tripInfos2;
    return _c('div', {
      key: "".concat(trip.departure, "-").concat(trip.arrival),
      staticClass: "flex-1"
    }, [_c('div', {
      staticClass: "d-flex align-items-center font-medium-5 fw-800 my-25"
    }, [_c('IAmIcon', {
      staticClass: "text-secondary mr-1",
      attrs: {
        "icon": "trainAndRail",
        "size": "55"
      }
    }), _vm._v(" " + _vm._s((_trip$tripInfos$0$dep = (_trip$tripInfos = trip.tripInfos) === null || _trip$tripInfos === void 0 ? void 0 : _trip$tripInfos[0].departureName) !== null && _trip$tripInfos$0$dep !== void 0 ? _trip$tripInfos$0$dep : trip.departure) + " - " + _vm._s((_trip$tripInfos$0$arr = (_trip$tripInfos2 = trip.tripInfos) === null || _trip$tripInfos2 === void 0 ? void 0 : _trip$tripInfos2[0].arrivalName) !== null && _trip$tripInfos$0$arr !== void 0 ? _trip$tripInfos$0$arr : trip.arrival) + " ")], 1), _c('div', {
      staticClass: "section-table train-pax-table-template-columns",
      class: [{
        'border-theme': _vm.config.showBackgroundColor
      }]
    }, [_c('div', {
      staticClass: "fw-700 d-flex align-items-center p-4px-16px",
      style: "\n          ".concat(_vm.config.showBackgroundColor ? "background-color: ".concat(_vm.currentBgColor, " !important") : '', "\n        "),
      attrs: {
        "contenteditable": ""
      }
    }, [_vm._v(" " + _vm._s(_vm.$t('ticket.paxTable.no', _vm.config.locale)) + " ")]), _c('div', {
      staticClass: "fw-700 d-flex align-items-center p-4px-16px",
      style: _vm.config.showBackgroundColor ? "background-color: ".concat(_vm.currentBgColor, " !important") : '',
      attrs: {
        "contenteditable": ""
      }
    }, [_vm._v(" " + _vm._s(_vm.$t('ticket.paxTable.passenger', _vm.config.locale)) + " ")]), _c('div', {
      staticClass: "fw-700 d-flex align-items-center p-4px-16px",
      style: _vm.config.showBackgroundColor ? "background-color: ".concat(_vm.currentBgColor, " !important") : '',
      attrs: {
        "contenteditable": ""
      }
    }, [_vm._v(" Tàu ")]), _c('div', {
      staticClass: "fw-700 d-flex align-items-center p-4px-16px",
      style: _vm.config.showBackgroundColor ? "background-color: ".concat(_vm.currentBgColor, " !important") : '',
      attrs: {
        "contenteditable": ""
      }
    }, [_vm._v(" Mã vé ")]), _c('div', {
      staticClass: "fw-700 d-flex align-items-center p-4px-16px",
      style: _vm.config.showBackgroundColor ? "background-color: ".concat(_vm.currentBgColor, " !important") : '',
      attrs: {
        "contenteditable": ""
      }
    }, [_vm._v(" Chỗ ")]), _c('div', {
      staticClass: "fw-700 d-flex align-items-center p-4px-16px",
      style: _vm.config.showBackgroundColor ? "background-color: ".concat(_vm.currentBgColor, " !important") : '',
      attrs: {
        "contenteditable": ""
      }
    }, [_vm._v(" Thời gian ")]), _vm._l(trip.tripInfos, function (ticket, ticketIndex) {
      return [_c('div', {
        key: "no-".concat(trip.departure, "-").concat(ticketIndex),
        staticClass: "text-18px p-4px-16px d-flex align-items-center w-100 border-t"
      }, [_c('div', {
        attrs: {
          "contenteditable": ""
        }
      }, [_vm._v(" " + _vm._s(String(ticketIndex + 1).padStart(2, "0")) + " ")])]), _c('div', {
        key: "pax-".concat(trip.departure, "-").concat(ticketIndex),
        staticClass: "p-4px-16px d-flex flex-column w-100 border-t"
      }, [_c('div', {
        staticClass: "w-100",
        attrs: {
          "contenteditable": ""
        }
      }, [_c('span', {
        staticClass: "text-18px fw-600"
      }, [_vm._v(" (" + _vm._s(_vm.getNamePaxType(ticket.passengerTargetCode)) + ") ")]), _c('span', {
        staticClass: "fw-700 text-20px"
      }, [_vm._v(" " + _vm._s(ticket.fullName) + " ")])]), _c('div', {
        staticClass: "text-18px text-secondary w-100",
        attrs: {
          "contenteditable": ""
        }
      }, [_vm._v(" Giấy tờ " + _vm._s(ticket.identifyNumber || '-') + " ")])]), _c('div', {
        key: "trainCode-".concat(trip.departure, "-").concat(ticketIndex),
        staticClass: "text-20px p-4px-16px d-flex flex-column justify-content-center w-100 fw-800 border-t"
      }, [_c('div', {
        staticStyle: {
          "width": "100%"
        },
        attrs: {
          "contenteditable": ""
        }
      }, [_c('span', {
        staticClass: "color-theme"
      }, [_vm._v(_vm._s(ticket.trainCode))])]), !ticket.trainCode ? _c('div', {
        staticClass: "color-theme",
        staticStyle: {
          "width": "100%"
        },
        attrs: {
          "contenteditable": ""
        }
      }) : _vm._e()]), _c('div', {
        key: "ticketId-".concat(trip.departure, "-").concat(ticketIndex),
        staticClass: "text-20px p-4px-16px d-flex flex-column justify-content-center w-100 fw-800 border-t"
      }, [_c('div', {
        staticStyle: {
          "width": "100%"
        },
        attrs: {
          "contenteditable": ""
        }
      }, [_c('span', {
        staticClass: "color-theme"
      }, [_vm._v(_vm._s(ticket.ticketId))])]), !ticket.ticketId ? _c('div', {
        staticClass: "color-theme",
        staticStyle: {
          "width": "100%"
        },
        attrs: {
          "contenteditable": ""
        }
      }) : _vm._e()]), _c('div', {
        key: "seatLocation-".concat(trip.departure, "-").concat(ticketIndex),
        staticClass: "text-16px p-4px-16px d-flex flex-column justify-content-center w-100 border-t"
      }, [_c('div', {
        staticStyle: {
          "width": "100%"
        },
        attrs: {
          "contenteditable": ""
        }
      }, [_c('span', {
        staticClass: "fw-800"
      }, [_vm._v("Toa " + _vm._s(ticket.coachName) + " - chỗ " + _vm._s(ticket.seatNumber))])]), _c('div', {
        staticStyle: {
          "width": "100%"
        },
        attrs: {
          "contenteditable": ""
        }
      }, [_c('span', [_vm._v(_vm._s(ticket.seatTypeName))])])]), _c('div', {
        key: "time-".concat(trip.departure, "-").concat(ticketIndex),
        staticClass: "text-16px p-4px-16px d-flex flex-column justify-content-center w-100 border-t"
      }, [_c('div', {
        staticStyle: {
          "width": "100%"
        },
        attrs: {
          "contenteditable": ""
        }
      }, [_c('span', {
        staticClass: "fw-800"
      }, [_vm._v(_vm._s(ticket.departureHour) + " " + _vm._s(_vm.convertISODateTime(ticket.departureTime).date) + " -")])]), _c('div', {
        staticStyle: {
          "width": "100%"
        },
        attrs: {
          "contenteditable": ""
        }
      }, [_c('span', [_vm._v(_vm._s(ticket.arrivalHour) + " " + _vm._s(_vm.convertISODateTime(ticket.arrivalTime).date))])])])];
    })], 2)]);
  }), 0);
}
var staticRenderFns = []

export { render, staticRenderFns }