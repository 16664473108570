<!-- eslint-disable vue/no-mutating-props -->
<template lang="">
  <!-- title & time hold -->
  <div class="d-flex-center justify-content-between text-20px fw-700 px-20px">
    <div
      class="color-theme text-uppercase"
      contenteditable="true"
    >
      {{ $t('ticket.eTicket', config.locale) }}
    </div>
    <div
      v-show="config.showTimeHold"
      class="text-red text-14px font-weight-bolder"
    >
      <template v-if="frontendI18n">
        {{ $t('ticket.prepaymentNote', config.locale) }}
      </template>
      <template v-else-if="!config.status.toUpperCase().includes('PAID')">
        <div
          contenteditable="true"
          @input="config.timeHoldNote = $event.target.textContent.trim()"
        >
          <span v-if="timeHold">
            {{ $t('ticket.payBefore', config.locale) }}: {{ timeHold }}
          </span>
        </div>
      </template>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    frontendI18n: {
      type: Boolean,
      default: false,
    },
    config: {
      type: Object,
      default: () => ({}),
    },
    timeHold: {
      type: String,
      default: '',
    },
  },
}
</script>
<style lang="">

</style>
