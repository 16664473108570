<!-- eslint-disable vue/no-mutating-props -->
<template>
  <div style="position: sticky; top: 0; z-index: 20;">
    <div
      class="position-absolute setting-icon-position"
      @click="showConfig = !showConfig"
    >
      <feather-icon
        id="reservation-ticket-rotation-settings-icon"
        size="24"
        icon="SettingsIcon"
        class="cursor-pointer text-danger"
      />
      <BTooltip
        target="reservation-ticket-rotation-settings-icon"
        placement="left"
        :title="$t('ticket.showHideConfig')"
      />
    </div>
    <b-card
      no-body
      header-tag="header"
      border-variant="info"
      header-bg-variant="light-info"
      header-class="py-1"
      :class="'border config-container ' + (showConfig ? 'show' : 'hide')"
    >
      <template #header>
        <h4 class="m-0">
          {{ $t('ticket.sectionsTitle.config') }}
        </h4>
      </template>
      <b-card-body class="py-1 px-2">
        <b-row>
          <b-col class="d-flex flex-column gap-2 mb-50">
            <label class="d-flex align-items-center cursor-pointer mb-0 fit-content">
              <b-form-checkbox
                v-model="config.showTimeHold"
                switch
                class="mr-25"
              />
              <span class="font-weight-bold">
                {{ $t('ticket.holdTime') }}
              </span>
            </label>

            <label class="d-flex align-items-center cursor-pointer mb-0 fit-content">
              <b-form-checkbox
                v-model="config.showStatus"
                switch
                class="mr-25"
              />
              <span class="font-weight-bold">
                {{ $t('ticket.ticketStatus') }}
              </span>
            </label>

            <label class="d-flex align-items-center cursor-pointer mb-0 fit-content">
              <b-form-checkbox
                v-model="config.showBookingClass"
                switch
                class="mr-25"
              />
              <span class="font-weight-bold">
                {{ $t('ticket.bookingClass') }}
              </span>
            </label>

            <label class="d-flex align-items-center cursor-pointer mb-0 fit-content">
              <b-form-checkbox
                v-model="config.useQuillEditor"
                switch
                class="mr-25"
              />
              <span class="font-weight-bold">
                {{ $t('ticket.useEditor') }}
              </span>
            </label>

            <label class="d-flex align-items-center cursor-pointer mb-0 fit-content">
              <b-form-checkbox
                v-model="config.showTicketNumber"
                switch
                class="mr-25"
              />
              <span class="font-weight-bold">
                {{ $t('ticket.ticketNumber') }}
              </span>
            </label>
          </b-col>

          <b-col class="d-flex flex-column gap-2 mb-50">
            <label class="d-flex align-items-center cursor-pointer mb-0 fit-content">
              <b-form-checkbox
                v-model="config.showPayment"
                switch
                class="mr-25"
              />
              <span class="font-weight-bold">
                {{ $t('ticket.paymentInfo') }}
              </span>
            </label>

            <label class="d-flex align-items-center cursor-pointer mb-0 fit-content">
              <b-form-checkbox
                v-model="config.showPrice"
                switch
                class="mr-25"
              />
              <span class="font-weight-bold">
                {{ $t('ticket.price') }}
              </span>
            </label>

            <label class="d-flex align-items-center cursor-pointer mb-0 fit-content">
              <b-form-checkbox
                v-model="config.showPriceNetFare"
                switch
                class="mr-25"
              />
              <span class="font-weight-bold">
                {{ $t('ticket.priceNetFare') }}
              </span>
            </label>

            <label class="d-flex align-items-center cursor-pointer mb-0 fit-content">
              <b-form-checkbox
                v-model="config.showPriceDetail"
                switch
                class="mr-25"
              />
              <span class="font-weight-bold">
                {{ $t('ticket.priceDetail') }}
              </span>
            </label>

            <label class="d-flex align-items-center cursor-pointer mb-0 fit-content">
              <b-form-checkbox
                v-model="config.showAncillaries"
                switch
                class="mr-25"
              />
              <span class="font-weight-bold">
                {{ $t('ticket.showAncillaries') }}
              </span>
            </label>
          </b-col>

          <b-col class="d-flex flex-column align-items-start gap-2">
            <label class="d-flex align-items-center cursor-pointer mb-0 fit-content">
              <b-form-checkbox
                v-model="config.showAgency"
                switch
                class="mr-25"
              />
              <span class="font-weight-bold">
                {{ $t('ticket.agencyInfo') }}
              </span>
            </label>

            <label class="d-flex align-items-center cursor-pointer mb-0 fit-content">
              <b-form-checkbox
                v-model="config.showAgencyLogo"
                switch
                class="mr-25"
              />
              <span class="font-weight-bold">
                Logo AG
              </span>
            </label>

            <label class="d-flex align-items-center cursor-pointer mb-0 fit-content">
              <b-form-checkbox
                v-model="config.showBackground"
                switch
                class="mr-25"
              />
              <span class="font-weight-bold">
                {{ $t('ticket.showBackground') }}
              </span>
            </label>

            <label class="d-flex align-items-center cursor-pointer mb-0 fit-content">
              <b-form-checkbox
                v-model="config.showBackgroundColor"
                switch
                class="mr-25"
              />
              <span class="font-weight-bold">
                {{ $t('ticket.showBackgroundColor') }}
              </span>
            </label>

            <label class="d-flex align-items-center cursor-pointer mb-0 fit-content">
              <b-form-checkbox
                v-model="config.showColorByAirline"
                switch
                class="mr-25"
              />
              <span class="font-weight-bold">
                {{ $t('ticket.showColorByAirline') }}
              </span>
            </label>

            <label class="d-flex align-items-center cursor-pointer mb-0 fit-content gap-16px">
              <span class="font-weight-bold">{{ $t('ticket.eticketTemplate.title') }}:</span>
              <b-form-radio-group v-model="config.eticketTemplate">
                <b-form-radio
                  v-for="eticketTemplate in ETICKET_TEMPLATE_OPTIONS"
                  :key="eticketTemplate.label"
                  class="custom-radio-eticket-template font-weight-bold"
                  :value="eticketTemplate.value"
                >
                  {{ $t(`ticket.eticketTemplate.${eticketTemplate.label}`) }}
                </b-form-radio>
              </b-form-radio-group>
            </label>
          </b-col>
        </b-row>

        <b-row class="mt-1">
          <b-col cols="6">
            <BFormGroup label-for="bankCode">
              <template #label>
                <div class="text-nowrap">
                  {{ $t('ticket.bankAccount') }}
                </div>
              </template>
              <v-select
                v-model="config.bankAccount"
                label="bankAccountNo"
                :options="bankAccountsList"
                :filterable="false"
                searchable
                :loading="loading"
                :placeholder="$t('ticket.phBankAccount')"
                :clearable="false"
                @open="fetchBankAccountsList"
              >
                <template #option="data">
                  <div class="d-flex-center justify-content-start">
                    <BImg
                      :src="resolveBankCode(data.bankCode).bankLogo"
                      width="50"
                    />
                    <div>{{ resolveBankCode(data.bankCode).bankName }} - <b>{{ data.bankAccountNo }}</b> - {{ data.bankAccountName }}</div>
                  </div>
                </template>

                <template #selected-option="data">
                  <div class="d-flex-center justify-content-start">
                    <BImg
                      :src="resolveBankCode(data.bankCode).bankLogo"
                      width="50"
                    />
                    <div>{{ resolveBankCode(data.bankCode).bankName }} - <b>{{ data.bankAccountNo }}</b> - {{ data.bankAccountName }}</div>
                  </div>
                </template>

                <template #spinner>
                  <div
                    v-if="loading"
                    style="border-left-color: rgba(88, 151, 251, 0.71)"
                    class="vs__spinner"
                  />
                </template>

                <template #no-options>
                  {{ $t('noOptions') }}
                </template>
              </v-select>
            </BFormGroup>
          </b-col>
          <b-col cols="3">
            <BFormGroup label-for="themeColor">
              <template #label>
                <div class="text-nowrap">
                  {{ $t('ticket.language') }}
                </div>
              </template>
              <v-select
                v-model="config.locale"
                :options="TICKET_LANGUAGE_OPTIONS"
                :reduce="val => val.value"
                :clearable="false"
              >
                <template #option="data">
                  <span>
                    {{ $t(data.label) }}
                  </span>
                </template>

                <template #selected-option="data">
                  <span>
                    {{ $t(data.label) }}
                  </span>
                </template>
              </v-select>
            </BFormGroup>
          </b-col>
          <b-col cols="3">
            <BFormGroup label-for="themeColor">
              <template #label>
                <div class="text-nowrap">
                  {{ $t('ticket.color') }}
                </div>
              </template>
              <v-select
                v-model="config.color"
                :options="TICKET_COLOR_OPTIONS"
                :reduce="val => val.value.toUpperCase()"
                :clearable="false"
              >
                <template #option="data">
                  <span>
                    {{ $t(data.label) }}
                  </span>
                </template>

                <template #selected-option="data">
                  <span>
                    {{ $t(data.label) }}
                  </span>
                </template>
              </v-select>
            </BFormGroup>
          </b-col>
        </b-row>
      </b-card-body>
    </b-card>
  </div>
</template>

<script>
import {
  BRow,
  BCol,
  BCard,
  BCardBody,
  BFormGroup,
  BFormCheckbox,
  BImg,
  BTooltip,
  BFormRadioGroup,
  BFormRadio,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import { computed, ref } from '@vue/composition-api'

import { TICKET_COLOR_OPTIONS, TICKET_LANGUAGE_OPTIONS, ETICKET_TEMPLATE_OPTIONS } from '@/constants/ticket'
import useBankAccounts from '@/views/agency-setting/useBankAccounts'
import { apiAgencies } from '@/api'
import store from '@/store'

export default {
  name: 'TicketConfig',
  components: {
    BRow,
    BCol,
    BCard,
    BCardBody,
    BFormGroup,
    BFormCheckbox,
    vSelect,
    BImg,
    BTooltip,
    BFormRadioGroup,
    BFormRadio,
  },
  props: {
    config: {
      type: Object,
      default: () => ({}),
    },
  },

  setup(props) {
    const bankAccountsList = ref()
    const meData = computed(() => store.getters['userStore/getMeData'])
    const loading = ref(false)
    const showConfig = ref(true)

    const bankAccount = ref(props.config.bankAccount || {})

    async function fetchBankAccountsList() {
      loading.value = true
      const agencyId = meData.value?.agency.id
      return apiAgencies.getBankAccountsList(agencyId)
        .then(res => {
          bankAccountsList.value = res.data.items.filter(item => !!item).map(item => ({ ...item, key: item.bankAccountNo }))
        })
        .finally(() => {
          loading.value = false
        })
    }

    const { findBank } = useBankAccounts()

    function resolveBankCode(bankCode) {
      const bankInfo = findBank(bankCode)
      return bankInfo
        ? {
          bankName: `${bankInfo?.shortName} (${bankInfo?.code})`,
          bankLogo: bankInfo?.logo,
        }
        : { bankName: bankCode }
    }

    return {
      TICKET_COLOR_OPTIONS,
      TICKET_LANGUAGE_OPTIONS,
      fetchBankAccountsList,
      resolveBankCode,
      bankAccountsList,
      loading,
      showConfig,
      bankAccount,
      ETICKET_TEMPLATE_OPTIONS,
    }
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>

<style lang="scss" scoped>
.setting-icon-position {
  top: 12px;
  right: 12px;
  z-index: 12;
}

.config-container {
  transition: 300ms ease all;

  &.hide {
    max-height: 0px;
    overflow: hidden;
    border: none !important;
  }

  &.show {
    max-height: 400px;
  }
}
</style>

<style lang="scss">
.custom-radio-eticket-template {
  margin-right: 1rem;
  .custom-control-label {
    margin-top: 1px;
  }
}
</style>
