var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "d-flex-center gap-2"
  }, [_c('div', {
    staticClass: "flex-1"
  }, [_c('div', {
    staticClass: "section-table pax-table-template-columns",
    class: [{
      'border-theme': !_vm.config.showBackgroundColor || _vm.isBasicType
    }, {
      'show-ticket-number-grid': _vm.config.showTicketNumber
    }],
    style: "\n        ".concat(_vm.isBasicType ? 'border-width: 2px;' : '', "\n        ").concat(_vm.config.showBackgroundColor && _vm.isBasicType ? "background-color: ".concat(_vm.currentBgColor, " !important") : '', "\n      ")
  }, [_c('div', {
    staticClass: "fw-700 d-flex align-items-center p-4px-16px",
    class: "".concat(_vm.isBasicType ? 'bg-theme-dark text-white' : ''),
    style: "\n          ".concat(_vm.config.showBackgroundColor && !_vm.isBasicType ? "background-color: ".concat(_vm.currentBgColor, " !important") : '', "\n        "),
    attrs: {
      "contenteditable": "true"
    }
  }, [_vm._v(" " + _vm._s(_vm.$t('ticket.paxTable.no', _vm.config.locale)) + " ")]), _c('div', {
    staticClass: "fw-700 d-flex align-items-center p-4px-16px",
    class: "".concat(_vm.isBasicType ? 'bg-theme-dark text-white' : ''),
    style: _vm.config.showBackgroundColor && !_vm.isBasicType ? "background-color: ".concat(_vm.currentBgColor, " !important") : '',
    attrs: {
      "contenteditable": "true"
    }
  }, [_vm._v(" " + _vm._s(_vm.$t('ticket.paxTable.passenger', _vm.config.locale)) + " ")]), _vm.config.showTicketNumber ? _c('div', {
    staticClass: "fw-700 d-flex align-items-center p-4px-16px",
    class: "".concat(_vm.isBasicType ? 'bg-theme-dark text-white' : ''),
    style: _vm.config.showBackgroundColor && !_vm.isBasicType ? "background-color: ".concat(_vm.currentBgColor, " !important") : '',
    attrs: {
      "contenteditable": "true"
    }
  }, [_vm._v(" " + _vm._s(_vm.$t('ticket.paxTable.ticketNumber', _vm.config.locale)) + " ")]) : _vm._e(), _c('div', {
    staticClass: "fw-700 d-flex align-items-center p-4px-16px",
    class: "".concat(_vm.isBasicType ? 'bg-theme-dark text-white' : ''),
    style: _vm.config.showBackgroundColor && !_vm.isBasicType ? "background-color: ".concat(_vm.currentBgColor, " !important") : '',
    attrs: {
      "contenteditable": "true"
    }
  }, [_vm._v(" " + _vm._s(_vm.$t('ticket.paxTable.additionalServices', _vm.config.locale)) + " ")]), _vm._l(_vm.newPaxs, function (item, index) {
    var _item$passenger, _item$passenger2, _item$passenger3, _item$passenger4, _item$passenger5, _item$passenger6, _item$passenger6$tick;
    return [_c('div', {
      key: "no-".concat(index),
      staticClass: "text-20px p-4px-16px d-flex align-items-center w-100 border-t"
    }, [_c('div', {
      attrs: {
        "contenteditable": "true"
      }
    }, [_vm._v(" " + _vm._s(String(index + 1).padStart(2, "0")) + " ")])]), _c('div', {
      key: "pax-".concat(index),
      staticClass: "p-4px-16px d-flex align-items-center w-100 border-t"
    }, [_c('div', {
      staticClass: "text-20px fw-700 w-100",
      attrs: {
        "contenteditable": "true"
      }
    }, [!_vm.frontendI18n && item !== null && item !== void 0 && (_item$passenger = item.passenger) !== null && _item$passenger !== void 0 && _item$passenger.paxType && item !== null && item !== void 0 && (_item$passenger2 = item.passenger) !== null && _item$passenger2 !== void 0 && _item$passenger2.title ? [_vm._v(" (" + _vm._s(item.passenger.title && _vm.$te("reservation.titleOptions.".concat(item.passenger.paxType, ".").concat(item.passenger.title), _vm.config.locale) ? _vm.$t("reservation.titleOptions.".concat(item.passenger.paxType, ".").concat(item.passenger.title), _vm.config.locale) : item.passenger.title) + ") ")] : _vm._e(), _c('span', {
      staticClass: "fw-700"
    }, [_vm._v(" " + _vm._s(_vm.frontendI18n && !_vm.isEmpty(item.passenger) ? _vm.$t(item.passenger) : _vm.getLocaleFullName(item.passenger)) + " ")]), (_item$passenger3 = item.passenger) !== null && _item$passenger3 !== void 0 && _item$passenger3.travelFlyer ? _c('span', {
      staticClass: "font-italic"
    }, [_vm._v(" (" + _vm._s((_item$passenger4 = item.passenger) === null || _item$passenger4 === void 0 ? void 0 : _item$passenger4.travelFlyer) + ") ")]) : _vm._e()], 2)]), _vm.config.showTicketNumber ? _c('div', {
      key: "ticketNumber-".concat(index),
      staticClass: "text-20px p-4px-16px d-flex flex-column align-items-center w-100 border-t"
    }, [_vm._l((_item$passenger5 = item.passenger) === null || _item$passenger5 === void 0 ? void 0 : _item$passenger5.ticketNumber, function (ticketNumber, ticketNumberIndex) {
      return _c('div', {
        key: "ticketNumber-".concat(ticketNumberIndex),
        staticStyle: {
          "width": "100%"
        },
        attrs: {
          "contenteditable": "true"
        }
      }, [_vm.isCombinate && ticketNumber !== null && ticketNumber !== void 0 && ticketNumber.source ? _c('span', [_vm._v("(" + _vm._s(ticketNumber === null || ticketNumber === void 0 ? void 0 : ticketNumber.source) + ")")]) : _vm._e(), _vm._v(" " + _vm._s(ticketNumber === null || ticketNumber === void 0 ? void 0 : ticketNumber.number) + " ")]);
    }), !(item.passenger && (_item$passenger6 = item.passenger) !== null && _item$passenger6 !== void 0 && (_item$passenger6$tick = _item$passenger6.ticketNumber) !== null && _item$passenger6$tick !== void 0 && _item$passenger6$tick.length) ? _c('div', {
      staticStyle: {
        "width": "100%"
      },
      attrs: {
        "contenteditable": "true"
      }
    }) : _vm._e()], 2) : _vm._e(), _c('div', {
      key: "bag-".concat(index),
      staticClass: "text-20px p-4px-16px d-flex align-items-center w-100 gap-1 border-t"
    }, [_c('div', {
      staticClass: "flex-1",
      attrs: {
        "contenteditable": "true"
      }
    }, [_vm.frontendI18n ? [_vm._v(" " + _vm._s(_vm.$t(item.bags, _vm.config.locale)) + " ")] : item.bags && item.bags.length ? [_vm._l(item.bags, function (bag, bagIndex) {
      return [bag.xBagName && bag.trip ? _c('div', {
        key: "BAG-".concat(bagIndex, "-").concat(bag.segmentIds[0], "-").concat(bag.paxId, "-").concat(bag.id)
      }, [_vm._v(" " + _vm._s(bag.trip) + ": " + _vm._s(bag.xBagName) + " ")]) : _vm._e()];
    })] : _vm._e(), item.others && item.others.length ? [_vm._l(item.others, function (other, otherIndex) {
      return [other.trip && other.otherName ? _c('div', {
        key: "OTHER-".concat(otherIndex, "-").concat(other.segmentIds[0], "-").concat(other.paxId, "-").concat(other.serviceSubCode, "-").concat(other.id)
      }, [_vm._v(" " + _vm._s(other.trip) + ": " + _vm._s(other.otherName) + " ")]) : _vm._e()];
    })] : _vm._e(), item.seat && item.seat.length ? [_vm._l(item.seat, function (seat, seatIndex) {
      return [seat.seatName ? _c('div', {
        key: "seat-".concat(seatIndex, "-").concat(seat.segmentIds[0], "-").concat(seat.paxId, "-").concat(seat.id, "-").concat(seat.pdcSeat)
      }, [_vm._v(" " + _vm._s(seat.trip) + ": " + _vm._s(seat.seatName) + " ")]) : _vm._e()];
    })] : _vm._e()], 2), _c('b-button', {
      staticClass: "pax-button",
      staticStyle: {
        "padding": "4px"
      },
      attrs: {
        "variant": "outline-danger"
      },
      on: {
        "click": function click($event) {
          return _vm.removePax(index);
        }
      }
    }, [_c('feather-icon', {
      attrs: {
        "icon": "MinusIcon",
        "size": "16"
      }
    })], 1), _c('b-button', {
      staticClass: "pax-button",
      staticStyle: {
        "padding": "4px"
      },
      attrs: {
        "variant": "outline-success"
      },
      on: {
        "click": function click($event) {
          return _vm.addPax(index);
        }
      }
    }, [_c('feather-icon', {
      attrs: {
        "icon": "PlusIcon",
        "size": "16"
      }
    })], 1)], 1)];
  })], 2)]), _vm.qrBookingInfo ? _c('div', {
    staticClass: "d-flex-center flex-column"
  }, [_c('div', {
    staticClass: "font-weight-bolder color-theme text-nowrap mb-50"
  }, [_vm._v(" " + _vm._s(_vm.$t('ticket.qrBookingInfo', _vm.config.locale)) + " ")]), _c('QrcodeVue', {
    attrs: {
      "value": _vm.qrBookingInfo,
      "size": "100",
      "level": "M"
    }
  })], 1) : _vm._e()]);
}
var staticRenderFns = []

export { render, staticRenderFns }