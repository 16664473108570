var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "d-flex justify-content-center flex-column text-20px px-20px"
  }, [_c('b-img', {
    staticStyle: {
      "height": "70px",
      "margin-bottom": "4px"
    },
    attrs: {
      "src": require('@/assets/icons/train-logo.svg')
    }
  }), _c('div', {
    staticClass: "text-uppercase text-center fw-800 mb-50",
    attrs: {
      "contenteditable": ""
    }
  }, [_vm._v(" Thẻ lên tàu hỏa/boarding pass ")]), _vm._m(0)], 1);
}
var staticRenderFns = [function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "text-center font-italic text-success font-small-4"
  }, [_c('div', {
    staticClass: "color-theme",
    attrs: {
      "contenteditable": ""
    }
  }, [_vm._v(" Đường sắt Bắc-Nam vinh dự được Lonely Planet bình chọn là tuyến đường đẹp nhất, đáng trải nghiệm nhất thế giới "), _c('br'), _vm._v(" The North-South railway route is proudly selected by Lonely Planet as the World’s most incredible and worth experiencing train journey ")])]);
}]

export { render, staticRenderFns }