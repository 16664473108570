<!-- eslint-disable vue/no-mutating-props -->
<template>
  <div
    class="e-ticket-train d-flex flex-column bg-white mx-auto"
    :class="[
      printSize,
      `theme-${currentTheme}`,
      { 'border border-theme': frontendI18n },
      { 'showBackgroundColor': config.showBackgroundColor}
    ]"
  >
    <TrainETicketAgencyInfo
      :config="config"
      :is-config="isConfig"
      :agencyAvatar="agencyAvatar"
      :editorOption="editorOption"
      :agencyInfo="agencyInfo"
      :handleResetAgencyInfo="handleResetAgencyInfo"
    />

    <TrainETicketTitle
      :frontendI18n="frontendI18n"
      :config="config"
    />

    <TrainETicketPassengerInfo
      :frontendI18n="frontendI18n"
      :config="config"
      :currentBgColor="currentBgColor"
      :trips="trips"
      :passengerTargetList="passengerTargetList"
      :addPax="addPax"
      :removePax="removePax"
    />

    <TrainETicketPriceInfo
      :config="config"
      :currentBgColor="currentBgColor"
      :ticketData="ticketData"
      :sumAll="sumAll"
      :trips="trips"
      :passengerTargetList="passengerTargetList"
      :handleChangePax="handleChangePax"
      :handleChangeSumAll="handleChangeSumAll"
      :handleChangeBookingTotal="handleChangeBookingTotal"
    />

    <TrainETicketNote
      :config="config"
      :editorOption="editorOption"
      :isConfig="isConfig"
      :qrCodeAvatar="qrCodeAvatar"
      :defaultBankAccount="defaultBankAccount"
      :resolveBankCode="resolveBankCode"
      :bankAvatar="bankAvatar"
      :handleResetNote="handleResetNote"
    />
  </div>
</template>

<script>
import { BButton, BImg } from 'bootstrap-vue'
import { computed, ref, watch } from '@vue/composition-api'
import Quill from 'quill'
import Vue from 'vue'

import {
  THEMES,
  BACKGROUND_COLOR_THEMES,
} from '@/constants/ticket'
import { toDataUrl } from '@/@core/utils/utils'
import store from '@/store'
import useBankAccounts from '@/views/agency-setting/useBankAccounts'

import useToast from '@useToast'

import TrainETicketAgencyInfo from './components/TrainETicketAgencyInfo.vue'
import TrainETicketTitle from './components/TrainETicketTitle.vue'
import TrainETicketPassengerInfo from './components/TrainETicketPassengerInfo.vue'
import TrainETicketPriceInfo from './components/TrainETicketPriceInfo.vue'
import TrainETicketNote from './components/TrainETicketNote.vue'

export default {
  components: {
    BButton,
    BImg,

    TrainETicketAgencyInfo,
    TrainETicketTitle,
    TrainETicketPassengerInfo,
    TrainETicketPriceInfo,
    TrainETicketNote,
  },
  props: {
    printSize: {
      type: String,
      default: 'xs',
    },
    frontendI18n: {
      type: Boolean,
      default: false,
    },
    config: {
      type: Object,
      default: () => ({
        showBackgroundColor: true,
      }),
    },
    listTrips: {
      type: Array,
      default: () => [],
    },
    passengerTargetList: {
      type: Array,
      default: () => [],
    },
    isConfig: {
      type: Boolean,
      default: false,
    },
    fromTicketData: {
      type: Object,
      default: () => ({
        pricingInfo: {
          netFare: 1000000,
          tax: 100000,
          total: 1100000,
          currency: 'VND',
        },
        totalFeeService: 50000,
        bookingCode: 'NYQWRN',
        status: 'HOLD',
      }),
    },
  },
  setup(props, { emit }) {
    const currentTheme = ref(0)
    const agencyAvatar = ref('')
    const trips = ref(props.listTrips)

    const agencyInfo = computed(() => store.getters['userStore/getAgencyData'])
    const currentBgColor = computed(() => BACKGROUND_COLOR_THEMES[currentTheme.value])

    const ticketData = ref(props.fromTicketData)

    watch(() => props.fromTicketData, val => {
      ticketData.value = val
    }, { immediate: true })
    watch(() => props.listTrips, val => {
      if (!val) return
      trips.value = val
    }, { immediate: true })
    watch(() => props.config.color, color => {
      currentTheme.value = THEMES[color.toLowerCase()]
    }, { immediate: true })
    watch(agencyInfo, async agency => {
      if (!agency) return
      agencyAvatar.value = await toDataUrl(agency.avatar)
    }, { immediate: true })

    const addTrip = index => {
      // tripInfo.value = [...tripInfo.value.slice(0, index + 1), { passenger: {} }, ...tripInfo.value.slice(index + 1)]
    }
    const removeTrip = index => {
      // if (tripInfo.value.length <= 1) return
      // tripInfo.value.splice(index, 1)
    }

    const addPax = index => {
      // tripInfo.value = [...tripInfo.value.slice(0, index + 1), { passenger: {} }, ...tripInfo.value.slice(index + 1)]
    }
    const removePax = index => {
      // if (tripInfo.value.length <= 1) return
      // tripInfo.value.splice(index, 1)
    }
    const handleChangePax = index => {
      // if (tripInfo.value.length <= 1) return
      // tripInfo.value.splice(index, 1)
    }
    const handleChangeSumAll = index => {
      // if (tripInfo.value.length <= 1) return
      // tripInfo.value.splice(index, 1)
    }
    const handleChangeBookingTotal = index => {
      // if (tripInfo.value.length <= 1) return
      // tripInfo.value.splice(index, 1)
    }

    function handleResetAgencyInfo() {
      emit('resetAgencyInfo')
    }

    function handleResetNote() {
      emit('resetNote')
    }

    const sumAll = computed(() => props.listTrips?.reduce((total, trip) => {
      const totalTripPrice = trip.tripInfos.reduce((sum, tk) => sum + tk.totalPrice, 0)
      return total + totalTripPrice
    }, 0))

    const ticketQRCode = ref('')
    const qrCodeAvatar = ref('')
    const defaultBankAccount = ref({})
    const isShowAlertBankAccount = ref(false)
    const bankAvatar = ref('')
    const listBanks = computed(() => store.getters['userStore/getListBank'])

    watch(() => [sumAll.value, props.config.showPrice, props.config.bankAccount], ([sumAll, showPrice, bankAccount]) => {
      if (isShowAlertBankAccount.value) return
      const description = ticketData.value ? ticketData.value.bookingCode : props.flights[0].bookingCode
      if (!agencyInfo.value) return
      if (!(agencyInfo.value?.bankAccs?.length ?? agencyInfo.value?.bankAccounts?.length)) {
        if (props.isConfig) {
          Vue.swal({
            title: 'Cảnh báo!',
            text: 'Đại lý chưa cài đặt tài khoản ngân hàng mặc định! Vui lòng liên hệ Admin đại lý.',
            icon: 'warning',
            showCancelButton: false,
            showConfirmButton: true,
            confirmButtonText: 'Đóng',
            customClass: {
              confirmButton: 'btn btn-outline-warning',
            },
            buttonsStyling: false,
          })
        } else {
          useToast().toast({
            title: 'messagesList.warning',
            content: 'Đại lý chưa cài đặt tài khoản ngân hàng mặc định! Vui lòng liên hệ Admin đại lý.',
            variant: 'warning',
          })
        }
        isShowAlertBankAccount.value = true
      } else {
        defaultBankAccount.value = bankAccount
        if (!defaultBankAccount.value) return
        const { bankCode, bankAccountNo, bankAccountName } = defaultBankAccount.value
        ticketQRCode.value = `https://img.vietqr.io/image/${bankCode}-${bankAccountNo}-qr_only.jpg?${showPrice ? `amount=${sumAll}&` : ''}addInfo=${description}&accountName=${bankAccountName}`
      }
    }, { immediate: true, deep: true })

    const { findBank } = useBankAccounts()

    function resolveBankCode(bankCode) {
      const bankInfo = findBank(bankCode)
      return bankInfo ? { bankName: `${bankInfo?.shortName} (${bankInfo?.code})`, bankLogo: bankInfo?.logo } : { bankName: bankCode }
    }

    watch(ticketQRCode, async qrCode => {
      qrCodeAvatar.value = await toDataUrl(qrCode)
    }, { immediate: true })
    watch(listBanks, async list => {
      if (!list?.length) {
        store.dispatch('userStore/fetchListBank')
      }
    }, { immediate: true })
    watch([defaultBankAccount, listBanks], async ([bankAccount]) => {
      const bank = resolveBankCode(bankAccount?.bankCode)
      bankAvatar.value = await toDataUrl(bank?.bankLogo)
    }, { immediate: true, deep: true })

    // ANCHOR - Quill Config -----
    const fontSizeArr = ['14px', '16px', '18px', '20px', '24px', '32px', '42px', '54px', '68px', '84px', '98px']
    const sizeStyle = Quill.import('attributors/style/size')
    sizeStyle.whitelist = [...fontSizeArr]
    Quill.register(sizeStyle, true)
    const fontStyleArr = ['Lato', 'arial', 'tinos', 'inconsolata', 'roboto', 'montserrat', 'sofia']
    const fontStyle = Quill.import('formats/font')
    fontStyle.whitelist = fontStyleArr
    Quill.register(fontStyle, true)
    const editorOption = ref({
      theme: 'bubble',
      syntax: false,
      modules: {
        toolbar: [
          [{ font: fontStyleArr }],
          ['bold', 'italic', 'underline', 'strike'],
          [{ header: [1, 2, 3, 4, 5, 6, false] }],
          [{ size: fontSizeArr }],
          [{ color: [] }, { background: [] }],
          ['blockquote', 'code-block'],
          [{ align: [] }],
          [{ list: 'ordered' }, { list: 'bullet' }],
        ],
      },
      placeholder: '',
    })
    // END Quill Config -----

    return {
      editorOption,
      currentTheme,
      currentBgColor,
      agencyAvatar,
      agencyInfo,
      trips,

      handleResetAgencyInfo,

      addTrip,
      removeTrip,

      addPax,
      removePax,
      handleChangePax,
      handleChangeSumAll,
      handleChangeBookingTotal,

      sumAll,
      ticketData,

      qrCodeAvatar,
      defaultBankAccount,
      resolveBankCode,
      bankAvatar,
      handleResetNote,
    }
  },

}
</script>

<style lang="scss">
  .e-ticket-train {
    padding: 24px;
    gap: 16px;
    border-radius: 12px;

    &.a4 {
      width: 100%;
      min-width: 100px;
    }

    &.theme-yellow {
      .color-theme {
        color: #efad02;
      }

      &.showBackgroundColor .bg-theme {
        background-color: #f5ef8a !important; // #efad0220;
      }

      .bg-theme-dark {
        background-color: #efad02 !important;
      }

      .border-theme {
        border: 1px solid #efad02;
      }

      .table-border-theme table td, .border-theme-top {
        border-top: 1px solid #efad02 !important;
      }

      .border-bottom-dashed-theme {
        width: 100%;
        margin-top: 4px;
        border-bottom: 2px dashed #efad02;
      }

      .divide-line {
        height: 100%;
        border: 1px solid #efad02;
        width: 0.5px;
      }
    }

    &.theme-red {
      .color-theme {
        color: #e22326;
      }

      &.showBackgroundColor .bg-theme {
        background-color: #F9D3D4 !important;
      }

      .bg-theme-dark {
        background-color: #e22326 !important;
      }

      .border-theme {
        border: 1px solid #e22326;
      }

      .table-border-theme table td, .border-theme-top {
        border-top: 1px solid #e22326 !important;
      }

      .border-bottom-dashed-theme {
        width: 100%;
        margin-top: 4px;
        border-bottom: 2px dashed #e22326;
      }

      .divide-line {
        height: 100%;
        border: 1px solid #e22326;
        width: 0.5px;
      }
    }

    &.theme-green {
      .color-theme {
        color: #45b143;
      }

      &.showBackgroundColor .bg-theme {
        background-color: #DAEFD9 !important;
      }

      .bg-theme-dark {
        background-color: #45b143 !important;
      }

      .border-theme {
        border: 1px solid #45b143;
      }

      .table-border-theme table td, .border-theme-top {
        border-top: 1px solid #45b143 !important;
      }

      .border-bottom-dashed-theme {
        width: 100%;
        margin-top: 4px;
        border-bottom: 2px dashed #45b143;
      }

      .divide-line {
        height: 100%;
        border: 1px solid #45b143;
        width: 0.5px;
      }
    }

    &.theme-blue {
      .color-theme {
        color: #0088FF;
      }

      &.showBackgroundColor .bg-theme {
        background-color: #DDF3FF !important;
      }

      .bg-theme-dark {
        background-color: #0088FF !important;
      }

      .border-theme {
        border: 1px solid #0088FF;
      }

      .table-border-theme table td, .border-theme-top {
        border-top: 1px solid #0088FF !important;
      }

      .border-bottom-dashed-theme {
        width: 100%;
        margin-top: 4px;
        border-bottom: 2px dashed #0088FF;
      }

      .divide-line {
        height: 100%;
        border: 1px solid #0088FF;
        width: 0.5px;
      }
    }

    &.theme-light_blue {
      .color-theme {
        color: #40B0C9;
      }

      &.showBackgroundColor .bg-theme {
        background-color: #D9EFF4 !important;
      }

      .bg-theme-dark {
        background-color: #40B0C9 !important;
      }

      .border-theme {
        border: 1px solid #40B0C9;
      }

      .table-border-theme table td, .border-theme-top {
        border-top: 1px solid #40B0C9 !important;
      }

      .border-bottom-dashed-theme {
        width: 100%;
        margin-top: 4px;
        border-bottom: 2px dashed #40B0C9;
      }

      .divide-line {
        height: 100%;
        border: 1px solid #40B0C9;
        width: 0.5px;
      }
    }

    &.theme-deep_ocean_blue {
      .color-theme {
        color: #1F6987FF;
      }

      &.showBackgroundColor .bg-theme {
        background-color: #D9EFF4 !important;
      }

      .bg-theme-dark {
        background-color: #1F6987FF !important;
      }

      .border-theme {
        border: 1px solid #1F6987FF;
      }

      .table-border-theme table td, .border-theme-top {
        border-top: 1px solid #1F6987FF !important;
      }

      .border-bottom-dashed-theme {
        width: 100%;
        margin-top: 4px;
        border-bottom: 2px dashed #1F6987FF;
      }

      .divide-line {
        height: 100%;
        border: 1px solid #1F6987FF;
        width: 0.5px;
      }
    }

    &.theme-black {
      .color-theme {
        color: #343434;
      }

      &.showBackgroundColor .bg-theme {
        background-color: #D6D6D6 !important;
      }

      .bg-theme-dark {
        background-color: #343434 !important;
      }

      .border-theme {
        border: 1px solid #343434;
      }

      .table-border-theme table td, .border-theme-top {
        border-top: 1px solid #343434 !important;
      }

      .border-bottom-dashed-theme {
        width: 100%;
        margin-top: 4px;
        border-bottom: 2px dashed #343434;
      }

      .divide-line {
        height: 100%;
        border: 1px solid #343434;
        width: 0.5px;
      }
    }

    &.theme-pink {
      .color-theme {
        color: #CD2A7A;
      }

      &.showBackgroundColor .bg-theme {
        background-color: #F5D4E4 !important;
      }

      .bg-theme-dark {
        background-color: #CD2A7A !important;
      }

      .border-theme {
        border: 1px solid #CD2A7A;
      }

      .table-border-theme table td, .border-theme-top {
        border-top: 1px solid #CD2A7A !important;
      }

      .border-bottom-dashed-theme {
        width: 100%;
        margin-top: 4px;
        border-bottom: 2px dashed #CD2A7A;
      }

      .divide-line {
        height: 100%;
        border: 1px solid #CD2A7A;
        width: 0.5px;
      }
    }

    &.theme-indigo {
      .color-theme {
        color: #6517F6;
      }

      &.showBackgroundColor .bg-theme {
        background-color: #E0D1FD !important;
      }

      .bg-theme-dark {
        background-color: #6517F6 !important;
      }

      .border-theme {
        border: 1px solid #6517F6;
      }

      .table-border-theme table td, .border-theme-top {
        border-top: 1px solid #6517F6 !important;
      }

      .border-bottom-dashed-theme {
        width: 100%;
        margin-top: 4px;
        border-bottom: 2px dashed #6517F6;
      }

      .divide-line {
        height: 100%;
        border: 1px solid #6517F6;
        width: 0.5px;
      }
    }

    &.theme-purple {
      .color-theme {
        color: #6E43C4;
      }

      &.showBackgroundColor .bg-theme {
        background-color: #E2D9F3 !important;
      }

      .bg-theme-dark {
        background-color: #6E43C4 !important;
      }

      .border-theme {
        border: 1px solid #6E43C4;
      }

      .table-border-theme table td, .border-theme-top {
        border-top: 1px solid #6E43C4 !important;
      }

      .border-bottom-dashed-theme {
        width: 100%;
        margin-top: 4px;
        border-bottom: 2px dashed #6E43C4;
      }

      .divide-line {
        height: 100%;
        border: 1px solid #6E43C4;
        width: 0.5px;
      }
    }

    &.theme-orange {
      .color-theme {
        color: #F47D00;
      }

      &.showBackgroundColor .bg-theme {
        background-color: #FDE5CC !important;
      }

      .bg-theme-dark {
        background-color: #F47D00 !important;
      }

      .border-theme {
        border: 1px solid #F47D00;
      }

      .table-border-theme table td, .border-theme-top {
        border-top: 1px solid #F47D00 !important;
      }

      .border-bottom-dashed-theme {
        width: 100%;
        margin-top: 4px;
        border-bottom: 2px dashed #F47D00;
      }

      .divide-line {
        height: 100%;
        border: 1px solid #F47D00;
        width: 0.5px;
      }
    }

    &.theme-teal {
      .color-theme {
        color: #48C995;
      }

      &.showBackgroundColor .bg-theme {
        background-color: #DAF4EA !important;
      }

      .bg-theme-dark {
        background-color: #48C995 !important;
      }

      .border-theme {
        border: 1px solid #48C995;
      }

      .table-border-theme table td, .border-theme-top {
        border-top: 1px solid #48C995 !important;
      }

      .border-bottom-dashed-theme {
        width: 100%;
        margin-top: 4px;
        border-bottom: 2px dashed #48C995;
      }

      .divide-line {
        height: 100%;
        border: 1px solid #48C995;
        width: 0.5px;
      }
    }

    &.theme-gray {
      .color-theme {
        color: #78808A;
      }

      &.showBackgroundColor .bg-theme {
        background-color: #E4E6E8 !important;
      }

      .bg-theme-dark {
        background-color: #78808A !important;
      }

      .border-theme {
        border: 1px solid #78808A;
      }

      .table-border-theme table td, .border-theme-top {
        border-top: 1px solid #78808A !important;
      }

      .border-bottom-dashed-theme {
        width: 100%;
        margin-top: 4px;
        border-bottom: 2px dashed #78808A;
      }

      .divide-line {
        height: 100%;
        border: 1px solid #78808A;
        width: 0.5px;
      }
    }

    .text-gray {
      color: #000000;
    }

    .border-gray {
      border-color: #747783;
    }

    .text-black {
      color: #000;
    }

    .text-red {
      color: #FF0000;
    }

    .flex {
      display: flex;
    }

    .section-agency {
      display: flex;
      padding: 0 20px ;
      gap: 40px;
      // border-radius: 12px;
      // border: 1px solid #E6E6E6;
    }

    .section-flight {
      border: 1px solid #E6E6E6
    }

    .segment-content {
      display: flex;
      align-items: center;
      position: relative;
      border-radius: 12px;
      border: 1px solid transparent;
    }

    .segment-content-l {
      // width: 55%;
      min-width: 560px;
      padding: 20px;
      z-index: 1;
      flex: 1;
    }

    .barcode-wrapper {
      display: flex;
      padding: 4px;
      align-items: center;
      align-self: stretch;
      flex-grow: 0;
      flex-shrink: 0;
      background: #fff;

      >img {
        width: 26px;
      }
    }

    .custom-v-line {
      border-left: 4px dashed white;
      margin-left: 8px;
    }

    .segment-content-r {
      max-width: 35%;
      flex-grow: 1;
      padding: 20px;
      z-index: 1;

      >.airline {
        >img.logo {
          height: 44px;
        }
      }
    }

    .group-class {
      font-family: Georgia, 'Times New Roman', Times, serif;
      font-size: 18px;
      letter-spacing: 1px;
      color: #305372;
    }

    .segment-booking-code {
      min-height: 67px;
      border-radius: 8px;
      overflow: hidden;

      >svg {
        top: 0;
        left: 0;
      }

      >.payment-status {
        right: -30px;
        width: 120px;
        padding: 4px 25px 3px;
        font-weight: 700;
        font-size: 8px;
        line-height: 1.2;
        text-align: center;
        text-transform: uppercase;
        transform: rotate(50deg);

        &.bg-current {
          background-color: currentColor;
        }
      }
    }

    .border-dot {
      border-bottom: 2px dashed;
    }

    .section-table {
      display: grid;
      border: 1px solid #E6E6E6;
      border-radius: 10px;
      overflow: hidden;

      &.train-pax-table-template-columns {
        grid-template-columns: 5% 30% 10% 15% 20% 20%;
      }

      &.price-table-template-columns {
        grid-template-columns: 1fr 1fr 1fr;
      }

      .border-t {
        border-top: 1px solid #E6E6E6;
      }

      &.table-responsive {
        border-top: 0px;
        border-bottom: 0px;

        tbody {
          border-left: 0px !important;
          border-right: 0px !important;
        }
      }
    }

    .pax-button {
      opacity: 0;
    }

    .section-flight:hover .pax-button,
    .train-pax-table-template-columns:hover .pax-button {
      opacity: 1;
    }

    .section-note {
      padding: 20px;
      border: 1px solid #E6E6E6;
      border-radius: 8px;
      display: flex;
      gap: 12px;
      align-items: flex-start;
    }

    .p-4px {
      padding: 4px;
    }

    .px-12px {
      padding-left: 12px;
      padding-right: 12px;
    }

    .px-20px {
      padding-left: 20px;
      padding-right: 20px;
    }

    .p-4px-8px {
      padding: 4px 8px !important;
    }

    .p-4px-16px {
      padding: 4px 16px;
    }

    .p-8px-16px {
      padding: 8px 16px;
    }

    .mt-4px {
      margin-top: 4px;
    }

    .my-12px {
      margin-top: 12px;
      margin-bottom: 12px;
    }

    .fw-700 {
      font-weight: 700;
    }

    .group {
      .plain {
        color: transparent;

        &:focus,
        &:hover,
        &:active {
          color: initial;

          +.formatter {
            display: none;
          }
        }
      }

      .formatter {
        top: 50%;
        transform: translateY(-50%);
        padding-left: 1em;
      }

      &:hover {
        .plain {
          color: inherit;
        }

        .formatter {
          display: none;
        }
      }
    }

    p,
    h6,
    h5,
    h4,
    h3,
    h2,
    h1 {
      margin: 0px;
      padding: 0px;
      line-height: unset;
    }

    .ql-editor {
      overflow: visible;
      padding: 0px !important;

      >* {
        line-height: unset;
      }
    }

    .ql-container {
      font-size: 16px
    }

    .ql-tooltip {
      z-index: 10000;
    }

    .table thead th {
      @extend .bg-theme;
      background-color: white !important;
    }

    #div-value-money {
      display: inline-flex;
      align-items: center;
      margin-bottom: -4px;
      width: 160px;
    }

    #value-money {
      display: inline;
      background-color: transparent;
      border: none;
      width: 100%;
    }
  }

  @import '@core/scss/vue/libs/quill.scss';
</style>
