<!-- eslint-disable vue/no-mutating-props -->
<template>
  <div
    class="e-ticket d-flex flex-column bg-white mx-auto"
    :class="[
      printSize,
      `theme-${currentTheme}`,
      { 'border border-theme': frontendI18n },
      { 'border-theme': eticketTemplate === 'basic' },
      { 'showBackgroundColor': config.showBackgroundColor}
    ]"
  >
    <template v-if="eticketTemplate === 'modern'">
      <ETicketAgencyInfo
        :config="config"
        :is-config="isConfig"
        :agencyAvatar="agencyAvatar"
        :editorOption="editorOption"
        :agencyInfo="agencyInfo"
        :handleResetAgencyInfo="handleResetAgencyInfo"
      />

      <ETicketTitleAndTimeHold
        :frontendI18n="frontendI18n"
        :config="config"
        :timeHold="timeHold"
      />

      <ETicketPassengerInfo
        :frontendI18n="frontendI18n"
        :config="config"
        :currentBgColor="currentBgColor"
        :qrBookingInfo="qrBookingInfo"
        :newPaxs="newPaxs"
        :removePax="removePax"
        :addPax="addPax"
        :isCombinate="combinate"
      />

      <ETicketItinerariesInfo
        :flights="flights"
        :ticketData="ticketData"
        :config="config"
        :currentBgColor="currentBgColor"
        :agencyAvatar="agencyAvatar"
        :getAirportName="getAirportName"
        :getGroupClass="getGroupClass"
        :getFreeBaggage="getFreeBaggage"
        :getHandBaggage="getHandBaggage"
        :getBaggageInBookingDetail="getBaggageInBookingDetail"
        :addFlight="addFlight"
        :removeFlight="removeFlight"
      />

      <ETicketPriceInfo
        :config="config"
        :ticketData="ticketData"
        :currentBgColor="currentBgColor"
        :fareBookingDataArray="fareBookingDataArray"
        :sumAll="sumAll"
        :totalAncillaryPriceRaw="totalAncillaryPriceRaw"
        :ancillaryTotal="ancillaryTotal"
        :showTotalPerTrip="showTotalPerTrip"
        :totalAncillaryPrice="totalAncillaryPrice"
        :handleChangePax="handleChangePax"
        :handleChangeAncillaryPrice="handleChangeAncillaryPrice"
        :handleChangeSumAll="handleChangeSumAll"
        :handleChangeAncillaryItem="handleChangeAncillaryItem"
        :handleChangeBookingTotal="handleChangeBookingTotal"
        :handleChangeAncillarySum="handleChangeAncillarySum"
        :ancillariesBookingDataArray="ancillariesBookingDataArray"
      />

      <ETicketNote
        :config="config"
        :editorOption="editorOption"
        :isConfig="isConfig"
        :qrCodeAvatar="qrCodeAvatar"
        :defaultBankAccount="defaultBankAccount"
        :resolveBankCode="resolveBankCode"
        :bankAvatar="bankAvatar"
        :handleResetNote="handleResetNote"
      />
    </template>
    <template v-else-if="eticketTemplate === 'basic'">
      <ETicketAgencyInfo
        :style="eticketTemplate === 'basic' ? 'background-color: #f6f6f6' : ''"
        class="rounded-lg"
        :config="config"
        :is-config="isConfig"
        :agencyAvatar="agencyAvatar"
        :editorOption="editorOption"
        :agencyInfo="agencyInfo"
        :handleResetAgencyInfo="handleResetAgencyInfo"
      />

      <ETicketTitleAndTimeHold
        :frontendI18n="frontendI18n"
        :config="config"
        :timeHold="timeHold"
      />

      <ETicketBookingCode
        :config="config"
        :flights="uniqBy(flights, 'bookingCode')"
        :ticketData="ticketData"
        :currentBgColor="currentBgColor"
        :addFlight="addFlight"
        :removeFlight="removeFlight"
      />

      <ETicketPassengerInfo
        :frontendI18n="frontendI18n"
        :config="config"
        :currentBgColor="currentBgColor"
        :qrBookingInfo="qrBookingInfo"
        :newPaxs="newPaxs"
        :removePax="removePax"
        :addPax="addPax"
        :eticketTemplate="eticketTemplate"
        :isCombinate="combinate"
      />

      <ETicketBasicItinerariesInfo
        :flights="flights"
        :ticketData="ticketData"
        :config="config"
        :currentBgColor="currentBgColor"
        :agencyAvatar="agencyAvatar"
        :getAirportName="getAirportName"
        :getGroupClass="getGroupClass"
        :getFreeBaggage="getFreeBaggage"
        :getHandBaggage="getHandBaggage"
        :getBaggageInBookingDetail="getBaggageInBookingDetail"
        :addFlight="addFlight"
        :removeFlight="removeFlight"
      />

      <ETicketPriceInfo
        :config="config"
        :ticketData="ticketData"
        :currentBgColor="currentBgColor"
        :fareBookingDataArray="fareBookingDataArray"
        :sumAll="sumAll"
        :totalAncillaryPriceRaw="totalAncillaryPriceRaw"
        :ancillaryTotal="ancillaryTotal"
        :showTotalPerTrip="showTotalPerTrip"
        :totalAncillaryPrice="totalAncillaryPrice"
        :handleChangePax="handleChangePax"
        :handleChangeAncillaryPrice="handleChangeAncillaryPrice"
        :handleChangeSumAll="handleChangeSumAll"
        :handleChangeAncillaryItem="handleChangeAncillaryItem"
        :handleChangeBookingTotal="handleChangeBookingTotal"
        :handleChangeAncillarySum="handleChangeAncillarySum"
        :eticketTemplate="eticketTemplate"
        :ancillariesBookingDataArray="ancillariesBookingDataArray"
      />

      <ETicketNote
        :eticketTemplate="eticketTemplate"
        :config="config"
        :editorOption="editorOption"
        :isConfig="isConfig"
        :qrCodeAvatar="qrCodeAvatar"
        :defaultBankAccount="defaultBankAccount"
        :resolveBankCode="resolveBankCode"
        :bankAvatar="bankAvatar"
        :handleResetNote="handleResetNote"
      />
    </template>
  </div>
</template>

<script>
import {
  computed, ref, watch, toRefs,
} from '@vue/composition-api'
import Vue from 'vue'
import Quill from 'quill'
import groupBy from 'lodash/groupBy'
import uniqBy from 'lodash/uniqBy'

import { iataCodeIndia } from '@/constants/selectOptions'
import { THEMES, BACKGROUND_COLOR_THEMES, FARE_BOOKING_DATA_ARRAY_SAMPLE } from '@/constants/ticket'
import store from '@/store'
import { toDataUrl } from '@/@core/utils/utils'
import useBankAccounts from '@/views/agency-setting/useBankAccounts'

import { convertISODateTime } from '@core/utils/filter'

import reservationStoreModule from '@reservation/reservationStoreModule'
import useReservationHandle from '@reservation/useReservationHandle'

import useToast from '@useToast'

import { getGroupClass, getFreeBaggage, getHandBaggage } from './useSegmentBaggage'
import ETicketAgencyInfo from './components/ETicketAgencyInfo.vue'
import ETicketPassengerInfo from './components/ETicketPassengerInfo.vue'
import ETicketItinerariesInfo from './components/ETicketItinerariesInfo.vue'
import ETicketPriceInfo from './components/ETicketPriceInfo.vue'
import ETicketNote from './components/ETicketNote.vue'
import ETicketTitleAndTimeHold from './components/ETicketTitleAndTimeHold.vue'
import ETicketBookingCode from './components/ETicketBookingCode.vue'
import ETicketBasicItinerariesInfo from './components/ETicketBasicItinerariesInfo.vue'

export default {
  name: 'ElectronicTicketTemplate',

  components: {
    ETicketAgencyInfo,
    ETicketPassengerInfo,
    ETicketItinerariesInfo,
    ETicketPriceInfo,
    ETicketNote,
    ETicketBookingCode,
    ETicketTitleAndTimeHold,
    ETicketBasicItinerariesInfo,
  },
  props: {
    frontendI18n: {
      type: Boolean,
      default: false,
    },
    config: {
      type: Object,
      default: () => ({}),
    },
    timeHold: {
      type: String,
      default: '',
    },
    fromFlights: {
      type: Array,
      default: () => [],
    },
    paxs: {
      type: Array,
      default: () => [],
    },
    isConfig: {
      type: Boolean,
      default: false,
    },
    fromTicketData: {
      type: Object,
      default: () => ({
        pricingInfo: {
          netFare: 1000000,
          tax: 100000,
          total: 1100000,
          currency: 'VND',
        },
        totalFeeService: 50000,
        bookingCode: 'NYQWRN',
        status: 'HOLD',
      }),
    },
    combinate: {
      type: Boolean,
      default: false,
    },
  },

  setup(props, { emit, root }) {
    const printSize = ref('a4')
    const RESERVATION_APP_STORE_MODULE_NAME = 'app-reservation'
    if (!store.hasModule(RESERVATION_APP_STORE_MODULE_NAME)) {
      store.registerModule(RESERVATION_APP_STORE_MODULE_NAME, reservationStoreModule)
    }
    const combinateRef = toRefs(props).combinate
    const { getBaggageInBookingDetail } = useReservationHandle()

    const ticketData = ref(props.fromTicketData)

    watch(() => props.fromTicketData, val => {
      ticketData.value = val
    }, { immediate: true })

    const flights = ref(props.fromFlights)

    // fetch unknown airport // thừa
    // watch(() => props.fromFlights, val => {
    //   flights.value = val
    //   const arrayAirport = []
    //   val.forEach(itinerary => {
    //     itinerary.segments.forEach(segment => {
    //       if (!arrayAirport.includes(segment.from)) {
    //         arrayAirport.push(segment.from)
    //       }
    //       if (!arrayAirport.includes(segment.to)) {
    //         arrayAirport.push(segment.to)
    //       }
    //     })
    //   })
    //   store.dispatch('app-reservation/fetchAirportsByCodes', arrayAirport)
    // }, { immediate: true })

    const removeFlight = index => {
      if (flights.value.length >= 1) { flights.value.splice(index, 1) }
    }

    const addFlight = (index, flight) => {
      flights.value = [...flights.value.slice(0, index + 1), flight, ...flights.value.slice(index + 1)]
    }

    function getAirportByAirportCode(airportCode) {
      if (!airportCode) return null
      return store.getters[`${RESERVATION_APP_STORE_MODULE_NAME}/getAirportByAirportCode`](airportCode)
    }

    function getAirportName(airportCode, showCode = true) {
      if (!airportCode) return null
      const airportObj = getAirportByAirportCode(airportCode)
      if (!airportObj) return airportCode

      let airportName
      const airportCodeText = showCode ? ` (${airportCode})` : ''
      if (airportObj.group === 'VIỆT NAM' || airportObj.group === 'VIETNAM') {
        airportName = `${this.$t(`vnAirports.${airportCode}`)}${airportCodeText}`
      } else {
        const isVn = props.config.locale === 'vi'
        const cityName = isVn ? airportObj.city : airportObj.cityEnName
        const countryName = isVn ? airportObj.countryName : airportObj.countryEnName
        airportName = `${cityName}${airportObj.countryName === 'Việt Nam' ? '' : `, ${countryName}`}${airportCodeText}`
      }
      return airportName
    }

    function resolvePaxFareInfoTicket(paxFareInfoTicket) {
      const { paxInfo } = paxFareInfoTicket
      const paxInfoGroup = groupBy(paxInfo, item => item.paxType)
      const res = Object.keys(paxInfoGroup).reduce((result, key) => {
        result[key] = {
          paxType: key,
          amountPassenger: paxInfoGroup[key].length,
          netPrice: paxInfoGroup[key].reduce((netPrice, item) => netPrice + item.netPrice, 0),
          discount: paxInfoGroup[key].reduce((discount, item) => discount + item.discount, 0),
          discountPostpaid: paxInfoGroup[key].reduce((discountPostpaid, item) => discountPostpaid + item.discountPostpaid, 0),
          fee: paxInfoGroup[key].reduce((fee, item) => fee + item.fee, 0),
          feeService: paxInfoGroup[key].reduce((feeService, item) => feeService + item.feeService, 0),
          tax: paxInfoGroup[key].reduce((tax, item) => tax + item.tax, 0),
          total: paxInfoGroup[key].reduce((total, item) => total + item.total, 0),
        }
        return result
      }, {})

      return Object.values(res)
    }

    // tính tổng tiền của paxFareInfoTicket
    const sumTotalArray = paxFareInfoTicket => {
      if (!paxFareInfoTicket) return null
      return resolvePaxFareInfoTicket(paxFareInfoTicket).reduce((sum, item) => sum + item.total, 0)
    }

    function getPricesByPriceQuotes(quoteList, paxLists, ancillaryServices) {
      const result = []
      for (const priceQuote of quoteList) {
        const amount = priceQuote.appliedPassengers.length
        const paxType = priceQuote.appliedPassengers[0].paxType

        const ancillaryPaxType = ancillaryServices.filter(ancillary => {
          const pax = paxLists.find(pax => pax.paxId === ancillary.paxId)
          return pax?.paxType === paxType
        })
        const ancillaryPrice = {
          netPrice: props.config.showAncillaries ? 0 : ancillaryPaxType.reduce((sum, item) => sum + item.basePrice, 0),
          tax: props.config.showAncillaries ? 0 : ancillaryPaxType.reduce((sum, item) => sum + item.totalTax, 0),
          total: props.config.showAncillaries ? 0 : ancillaryPaxType.reduce((sum, item) => sum + item.totalPrice, 0),
        }

        result.push({
          paxType,
          flight: priceQuote.bookingInfos ? priceQuote.bookingInfos.map(item => `${getAirportName(item.departure)} - ${getAirportName(item.arrival)}`).join(', ') : '',
          departureDate: convertISODateTime(priceQuote.bookingInfos[0].departureDate, priceQuote.bookingInfos[0].departureTimezone).ISOdatetime,
          segmentId: priceQuote.bookingInfos ? priceQuote.bookingInfos.map(item => `${item.segmentId}`).join(', ') : '',
          amountPassenger: amount,
          fareBasisCode: priceQuote.fareInfos.map(fare => fare.fareBasisCode).join(', '),
          expirationTime: ticketData.value?.source === '1G' ? convertISODateTime(priceQuote.expirationTime).timeDate : convertISODateTime(priceQuote.expirationTime).timeDate,
          lastDateToPurchase: convertISODateTime(priceQuote.lastDateToPurchase).timeDate,
          feeService: (priceQuote.feeService || 0) * amount,
          netPrice: (priceQuote.basePriceTicket || priceQuote.basePrice) * amount + ancillaryPrice.netPrice,
          tax: (priceQuote.totalTaxesTicket || priceQuote.totalTaxes) * amount + ancillaryPrice.tax,
          total: (priceQuote.totalPriceTicket || priceQuote.totalPrice) * amount + ancillaryPrice.total,
        })
      }
      const groupby = Object.values(groupBy(result, ({ flight, segmentId }) => `${segmentId}|${flight}`))
        .sort((a, b) => Number(a[0].segmentId) - Number(b[0].segmentId))
      const res = groupby.map(item => ({
        trip: item[0].flight,
        departureDate: item[0].departureDate,
        paxFare: item,
        total: item.reduce((a, c) => a + c.total, 0),
      }))
      return res
    }

    function convertBookingDataToFare(bookingData) {
      if (bookingData.source === 'F1') {
        // const departureTime = bookingData.itineraries[0][0].departure || null
        const passengerGroupBy = groupBy(bookingData.paxLists, item => item.paxType)
        const passenger = Object.keys(passengerGroupBy).map(key => `${root.$t(`reservation.${key}`)} x${passengerGroupBy[key].length}`).join(', ')
        const fareTotal = bookingData.paxFareInfoTicket.reduce((sum, paxFareInfoTicketItem) => sum + paxFareInfoTicketItem.paxInfo.reduce((s, pax) => s + pax.total, 0), 0)
        return [{
          trip: bookingData.itineraries.map(itinerary => itinerary.map(segment => `${getAirportName(segment.departure.iataCode)} - ${getAirportName(segment.arrival.iataCode)}`).join(', ')).join(', '),
          fareInfoTicket: [{
            total: fareTotal,
            passenger,
          }],
          total: fareTotal,
        }]
      } if (bookingData?.paxFareInfoTicket?.length) {
        return bookingData.paxFareInfoTicket.map(paxFareInfoTicketItem => {
          const paxFare = resolvePaxFareInfoTicket(paxFareInfoTicketItem)
          return {
            trip: paxFareInfoTicketItem.trips.map(trip => `${getAirportName(trip.departure)} - ${getAirportName(trip.arrival)}`).join(', '),
            departureDate: convertISODateTime(paxFareInfoTicketItem?.trips[0]?.departureDate, paxFareInfoTicketItem?.trips[0]?.departureTz).ISOdatetime,
            paxFare,
            total: paxFare.reduce((a, cur) => a + cur.total, 0),
          }
        })
      }
      if (bookingData?.priceQuotes?.length) {
        return getPricesByPriceQuotes(bookingData.priceQuotes, bookingData.paxLists, bookingData.ancillaryServices)
      }
      if (!bookingData?.priceQuotes?.length && bookingData?.historicalPriceQuotes?.length) {
        return getPricesByPriceQuotes(bookingData.historicalPriceQuotes, bookingData.paxLists, bookingData.ancillaryServices)
      }
      return null
    }

    const fareBookingDataArray = ref([])
    const ancillariesBookingDataArray = ref([])
    const totalAncillaryPriceRaw = ref(0) //  tổng tiền dịch vụ trong booking
    const totalAncillaryPriceCustom = ref(0) //  tổng tiền dịch vụ chênh khi sửa
    const totalAncillaryPrice = computed(() => totalAncillaryPriceRaw.value + totalAncillaryPriceCustom.value) //  tổng tiền dịch vụ hiển thị (có thể chỉnh sửa)
    watch(() => [combinateRef.value, ticketData.value, props.config.showAncillaries], ([isCombinate, ticketData]) => {
      const bookings = [ticketData]
      if (isCombinate && ticketData.roundtripBooking) {
        bookings.push(ticketData.roundtripBooking)
      } else if (isCombinate && ticketData.multiCityBookings) {
        bookings.push(...ticketData.multiCityBookings.filter(booking => !['CANCEL'].includes(booking?.status)))
      }
      bookings.sort((a, b) => a?.id - b?.id ? 1 : -1)

      if (!props.isConfig) {
        fareBookingDataArray.value = bookings
          .map(booking => convertBookingDataToFare(booking))
          .flatMap(a => a)
          .filter(Boolean)
          .sort((a, b) => (new Date(a.departureDate)) - (new Date(b.departureDate)))

        ancillariesBookingDataArray.value = bookings
          .filter(booking => booking?.source?.includes('1S'))
          .map(item => ({
            ...item,
            ancillaryTotal: item.ancillaryServices.reduce((sum, a) => sum + a.totalPrice, 0),
          }))
          .sort((a, b) => (new Date(a.departureDate)) - (new Date(b.departureDate)))
        totalAncillaryPriceRaw.value = bookings.reduce((total, b) => total + b.ancillaryServices.reduce((sum, item) => sum + item.totalPrice, 0), 0)
      } else {
        fareBookingDataArray.value = FARE_BOOKING_DATA_ARRAY_SAMPLE
        ancillariesBookingDataArray.value = []
      }
    }, { immediate: true, deep: true })

    // Sửa tiền dịch vụ
    const handleChangeAncillaryPrice = text => {
      totalAncillaryPriceCustom.value = Number(text) - totalAncillaryPriceRaw.value
    }

    // Ẩn / hiện Tổng của các bảng
    const showTotalPerTrip = computed(() => fareBookingDataArray.value.flat().length > 1)

    const ancillaryTotal = computed(() => ancillariesBookingDataArray.value.reduce((sum, item) => sum + item.ancillaryTotal, 0))

    // Tổng tiền cuối cùng
    const sumAll = computed(() => fareBookingDataArray.value?.flat()?.reduce(
      (a, c) => a + (c?.total || 0),
      props.config.showAncillaries ? ancillaryTotal.value : 0,
    ) + (totalAncillaryPrice.value || 0))

    const handleChangeSumAll = text => {
      const difTotal = Number(text) - sumAll.value
      fareBookingDataArray.value.forEach((booking, index, array) => {
        const difPerBooking = Math.floor(difTotal / array.length)
        booking.total += difPerBooking
        const numPax = booking.paxFare
          .filter(pax => pax.paxType !== 'INFANT')
          .reduce((sum, pax) => sum + pax.amountPassenger, 0)
        booking.paxFare
          .filter(pax => pax.paxType !== 'INFANT')
          .forEach(pax => {
            const difPerPax = Math.floor((difPerBooking * pax.amountPassenger) / numPax)
            pax.netPrice += difPerPax
            pax.total += difPerPax
          })
      })
    }

    const handleChangeBookingTotal = (text, index) => {
      const booking = fareBookingDataArray.value[index]
      const difPerBooking = Number(text) - booking.total
      booking.total = Number(text)
      const numPax = booking.paxFare
        .filter(pax => pax.paxType !== 'INFANT')
        .reduce((sum, pax) => sum + pax.amountPassenger, 0)
      booking.paxFare
        .filter(pax => pax.paxType !== 'INFANT')
        .forEach(pax => {
          const difPerPax = Math.floor((difPerBooking * pax.amountPassenger) / numPax)
          pax.netPrice += difPerPax
          pax.total += difPerPax
        })
    }

    const handleChangePax = (text, index, paxType, type, isChangeFareTicket = false) => {
      const booking = fareBookingDataArray.value[index]
      if (isChangeFareTicket) {
        const pax = booking.fareInfoTicket.find(pax => pax.passenger === paxType)
        pax[type] = Number(text)
        if (type === 'total') {
          pax.netPrice = pax.total - pax.tax
        } else {
          pax.total = pax.netPrice + pax.tax
        }
        booking.total = booking.fareInfoTicket.reduce((sum, pax) => sum + pax.total, 0)
      } else {
        const pax = booking.paxFare.find(pax => pax.paxType === paxType)
        pax[type] = Number(text) * pax.amountPassenger
        if (type === 'total') {
          pax.netPrice = pax.total - pax.tax
        } else {
          pax.total = pax.netPrice + pax.tax
        }
        booking.total = booking.paxFare.reduce((sum, pax) => sum + pax.total, 0)
      }
    }

    const handleChangeAncillarySum = (text, index) => {
      const booking = ancillariesBookingDataArray.value[index]
      const difPerBooking = Number(text) - booking.ancillaryTotal
      booking.ancillaryServices.forEach((ancillary, index, array) => {
        const difPerAncillary = Math.floor(difPerBooking / array.length)
        ancillary.basePrice += difPerAncillary
        ancillary.totalPrice += difPerAncillary
      })
    }

    const handleChangeAncillaryItem = (text, index, itemId) => {
      const booking = ancillariesBookingDataArray.value[index]
      const ancillary = booking.ancillaryServices.find(item => item.id === itemId)
      ancillary.totalPrice = Number(text)
      ancillary.basePrice = ancillary.totalPrice - ancillary.totalTax
    }

    const currentTheme = ref(0)

    const fontSizeArr = ['14px', '16px', '18px', '20px', '24px', '32px', '42px', '54px', '68px', '84px', '98px']
    const sizeStyle = Quill.import('attributors/style/size')
    sizeStyle.whitelist = [...fontSizeArr]
    Quill.register(sizeStyle, true)

    const fontStyleArr = ['Lato', 'arial', 'tinos', 'inconsolata', 'roboto', 'montserrat', 'sofia']
    const fontStyle = Quill.import('formats/font')
    fontStyle.whitelist = fontStyleArr
    Quill.register(fontStyle, true)

    const editorOption = ref({
      theme: 'bubble',
      syntax: false,
      modules: {
        toolbar: [
          [{ font: fontStyleArr }],
          ['bold', 'italic', 'underline', 'strike'],
          [{ header: [1, 2, 3, 4, 5, 6, false] }],
          [{ size: fontSizeArr }],
          [{ color: [] }, { background: [] }],
          ['blockquote', 'code-block'],
          [{ align: [] }],
          [{ list: 'ordered' }, { list: 'bullet' }],
        ],
      },
      placeholder: '',
    })

    const agencyInfo = computed(() => store.getters['userStore/getAgencyData'])

    const ticketQRCode = ref('')
    const defaultBankAccount = ref({})

    const isShowAlertBankAccount = ref(false)
    watch(() => [sumAll.value, props.config.showPrice, props.config.bankAccount], ([sumAll, showPrice, bankAccount]) => {
      if (isShowAlertBankAccount.value) return
      const description = ticketData.value ? ticketData.value.bookingCode : props.flights[0].bookingCode
      if (!agencyInfo.value) return
      if (!(agencyInfo.value?.bankAccs?.length ?? agencyInfo.value?.bankAccounts?.length)) {
        if (props.isConfig) {
          Vue.swal({
            title: 'Cảnh báo!',
            text: 'Đại lý chưa cài đặt tài khoản ngân hàng mặc định! Vui lòng liên hệ Admin đại lý.',
            icon: 'warning',
            showCancelButton: false,
            showConfirmButton: true,
            confirmButtonText: 'Đóng',
            customClass: {
              confirmButton: 'btn btn-outline-warning',
            },
            buttonsStyling: false,
          })
        } else {
          useToast().toast({
            title: 'messagesList.warning',
            content: 'Đại lý chưa cài đặt tài khoản ngân hàng mặc định! Vui lòng liên hệ Admin đại lý.',
            variant: 'warning',
          })
        }
        isShowAlertBankAccount.value = true
      } else {
        defaultBankAccount.value = bankAccount
        if (!defaultBankAccount.value) return
        const { bankCode, bankAccountNo, bankAccountName } = defaultBankAccount.value
        ticketQRCode.value = `https://img.vietqr.io/image/${bankCode}-${bankAccountNo}-qr_only.jpg?${showPrice ? `amount=${sumAll}&` : ''}addInfo=${description}&accountName=${bankAccountName}`
      }
    }, { immediate: true, deep: true })

    const { findBank } = useBankAccounts()
    function resolveBankCode(bankCode) {
      const bankInfo = findBank(bankCode)
      return bankInfo ? { bankName: `${bankInfo?.shortName} (${bankInfo?.code})`, bankLogo: bankInfo?.logo } : { bankName: bankCode }
    }

    const agencyAvatar = ref('')
    watch(agencyInfo, async agency => {
      if (agency) {
        agencyAvatar.value = await toDataUrl(agency.avatar)
      }
    }, { immediate: true })

    const qrCodeAvatar = ref('')
    watch(ticketQRCode, async qrCode => {
      qrCodeAvatar.value = await toDataUrl(qrCode)
    }, { immediate: true })

    const bankAvatar = ref('')

    const listBanks = computed(() => store.getters['userStore/getListBank'])

    watch(listBanks, async list => {
      if (!list?.length) {
        store.dispatch('userStore/fetchListBank')
      }
    }, { immediate: true })

    watch([defaultBankAccount, listBanks], async ([bankAccount]) => {
      const bank = resolveBankCode(bankAccount?.bankCode)
      bankAvatar.value = await toDataUrl(bank?.bankLogo)
    }, { immediate: true, deep: true })

    watch(() => props.config.color, color => {
      currentTheme.value = THEMES[color.toLowerCase()]
    }, { immediate: true })

    watch(() => ticketData.value.status, val => {
      // eslint-disable-next-line vue/no-mutating-props
      props.config.status = val
      emit('update:config', props.config)
    }, { immediate: true })

    const newPaxs = ref(props.paxs)
    watch(() => props.paxs, val => {
      newPaxs.value = val
    }, { immediate: true })

    const removePax = index => {
      if (newPaxs.value.length > 1) { newPaxs.value.splice(index, 1) }
    }

    const addPax = index => {
      newPaxs.value = [...newPaxs.value.slice(0, index + 1), { passenger: {} }, ...newPaxs.value.slice(index + 1)]
    }

    function handleResetNote() {
      emit('resetNote')
    }

    function handleResetAgencyInfo() {
      emit('resetAgencyInfo')
    }

    const currentBgColor = computed(() => BACKGROUND_COLOR_THEMES[currentTheme.value])

    const qrBookingInfo = computed(() => {
      const { paxLists } = ticketData.value
      let airline
      const findTrip = flights.value.find(f => f.segments.some(seg => {
        if (iataCodeIndia.includes(seg?.from) || iataCodeIndia.includes(seg?.to)) {
          airline = seg.airline
          return true
        }
        return false
      }))
      if (!findTrip) return false
      const { bookingCode } = findTrip
      const firstPassengerName = `${paxLists[0]?.lastName}/${paxLists[0]?.firstName}`
      return `${bookingCode}|${firstPassengerName}|${airline}`
    })

    const eticketTemplate = computed(() => props.config.eticketTemplate?.toLowerCase()) // basic, modern

    return {
      printSize,
      currentTheme,
      currentBgColor,
      getAirportName,
      agencyInfo,
      agencyAvatar,
      qrCodeAvatar,
      bankAvatar,
      defaultBankAccount,
      getBaggageInBookingDetail,
      resolveBankCode,
      newPaxs,
      removePax,
      addPax,
      handleResetNote,
      handleResetAgencyInfo,
      combinateRef,
      ticketData,
      flights,
      removeFlight,
      addFlight,
      sumTotalArray,
      getPricesByPriceQuotes,
      resolvePaxFareInfoTicket,
      fareBookingDataArray,
      ancillariesBookingDataArray,
      ancillaryTotal,
      sumAll,
      showTotalPerTrip,
      handleChangeSumAll,
      handleChangeBookingTotal,
      handleChangePax,
      handleChangeAncillarySum,
      handleChangeAncillaryItem,
      totalAncillaryPrice,
      totalAncillaryPriceRaw,
      handleChangeAncillaryPrice,
      qrBookingInfo,
      fontSizeArr,
      fontStyleArr,
      editorOption,
      eticketTemplate,
      uniqBy,
    }
  },

  methods: {
    getGroupClass,
    getHandBaggage,
    getFreeBaggage,
  },
}
</script>

<style lang="scss">
  .e-ticket {
    padding: 24px;
    gap: 16px;
    border-radius: 12px;

    &.a4 {
      width: 100%;
      min-width: 100px;
    }

    &.theme-yellow {
      .color-theme {
        color: #efad02;
      }

      &.showBackgroundColor .bg-theme {
        background-color: #f5ef8a !important; // #efad0220;
      }

      .bg-theme-dark {
        background-color: #efad02 !important;
      }

      .border-theme {
        border: 1px solid #efad02;
      }

      .table-border-theme table td, .border-theme-top {
        border-top: 1px solid #efad02 !important;
      }

      .border-bottom-dashed-theme {
        width: 100%;
        margin-top: 4px;
        border-bottom: 2px dashed #efad02;
      }

      .divide-line {
        height: 100%;
        border: 1px solid #efad02;
        width: 0.5px;
      }
    }

    &.theme-red {
      .color-theme {
        color: #e22326;
      }

      &.showBackgroundColor .bg-theme {
        background-color: #F9D3D4 !important;
      }

      .bg-theme-dark {
        background-color: #e22326 !important;
      }

      .border-theme {
        border: 1px solid #e22326;
      }

      .table-border-theme table td, .border-theme-top {
        border-top: 1px solid #e22326 !important;
      }

      .border-bottom-dashed-theme {
        width: 100%;
        margin-top: 4px;
        border-bottom: 2px dashed #e22326;
      }

      .divide-line {
        height: 100%;
        border: 1px solid #e22326;
        width: 0.5px;
      }
    }

    &.theme-green {
      .color-theme {
        color: #45b143;
      }

      &.showBackgroundColor .bg-theme {
        background-color: #DAEFD9 !important;
      }

      .bg-theme-dark {
        background-color: #45b143 !important;
      }

      .border-theme {
        border: 1px solid #45b143;
      }

      .table-border-theme table td, .border-theme-top {
        border-top: 1px solid #45b143 !important;
      }

      .border-bottom-dashed-theme {
        width: 100%;
        margin-top: 4px;
        border-bottom: 2px dashed #45b143;
      }

      .divide-line {
        height: 100%;
        border: 1px solid #45b143;
        width: 0.5px;
      }
    }

    &.theme-blue {
      .color-theme {
        color: #0088FF;
      }

      &.showBackgroundColor .bg-theme {
        background-color: #DDF3FF !important;
      }

      .bg-theme-dark {
        background-color: #0088FF !important;
      }

      .border-theme {
        border: 1px solid #0088FF;
      }

      .table-border-theme table td, .border-theme-top {
        border-top: 1px solid #0088FF !important;
      }

      .border-bottom-dashed-theme {
        width: 100%;
        margin-top: 4px;
        border-bottom: 2px dashed #0088FF;
      }

      .divide-line {
        height: 100%;
        border: 1px solid #0088FF;
        width: 0.5px;
      }
    }

    &.theme-light_blue {
      .color-theme {
        color: #40B0C9;
      }

      &.showBackgroundColor .bg-theme {
        background-color: #D9EFF4 !important;
      }

      .bg-theme-dark {
        background-color: #40B0C9 !important;
      }

      .border-theme {
        border: 1px solid #40B0C9;
      }

      .table-border-theme table td, .border-theme-top {
        border-top: 1px solid #40B0C9 !important;
      }

      .border-bottom-dashed-theme {
        width: 100%;
        margin-top: 4px;
        border-bottom: 2px dashed #40B0C9;
      }

      .divide-line {
        height: 100%;
        border: 1px solid #40B0C9;
        width: 0.5px;
      }
    }

    &.theme-deep_ocean_blue {
      .color-theme {
        color: #1F6987;
      }

      &.showBackgroundColor .bg-theme {
        background-color: #D9EFF4 !important;
      }

      .bg-theme-dark {
        background-color: #1F6987 !important;
      }

      .border-theme {
        border: 1px solid #1F6987;
      }

      .table-border-theme table td, .border-theme-top {
        border-top: 1px solid #1F6987 !important;
      }

      .border-bottom-dashed-theme {
        width: 100%;
        margin-top: 4px;
        border-bottom: 2px dashed #1F6987;
      }

      .divide-line {
        height: 100%;
        border: 1px solid #1F6987;
        width: 0.5px;
      }
    }

    &.theme-black {
      .color-theme {
        color: #343434;
      }

      &.showBackgroundColor .bg-theme {
        background-color: #D6D6D6 !important;
      }

      .bg-theme-dark {
        background-color: #343434 !important;
      }

      .border-theme {
        border: 1px solid #343434;
      }

      .table-border-theme table td, .border-theme-top {
        border-top: 1px solid #343434 !important;
      }

      .border-bottom-dashed-theme {
        width: 100%;
        margin-top: 4px;
        border-bottom: 2px dashed #343434;
      }

      .divide-line {
        height: 100%;
        border: 1px solid #343434;
        width: 0.5px;
      }
    }

    &.theme-pink {
      .color-theme {
        color: #CD2A7A;
      }

      &.showBackgroundColor .bg-theme {
        background-color: #F5D4E4 !important;
      }

      .bg-theme-dark {
        background-color: #CD2A7A !important;
      }

      .border-theme {
        border: 1px solid #CD2A7A;
      }

      .table-border-theme table td, .border-theme-top {
        border-top: 1px solid #CD2A7A !important;
      }

      .border-bottom-dashed-theme {
        width: 100%;
        margin-top: 4px;
        border-bottom: 2px dashed #CD2A7A;
      }

      .divide-line {
        height: 100%;
        border: 1px solid #CD2A7A;
        width: 0.5px;
      }
    }

    &.theme-indigo {
      .color-theme {
        color: #6517F6;
      }

      &.showBackgroundColor .bg-theme {
        background-color: #E0D1FD !important;
      }

      .bg-theme-dark {
        background-color: #6517F6 !important;
      }

      .border-theme {
        border: 1px solid #6517F6;
      }

      .table-border-theme table td, .border-theme-top {
        border-top: 1px solid #6517F6 !important;
      }

      .border-bottom-dashed-theme {
        width: 100%;
        margin-top: 4px;
        border-bottom: 2px dashed #6517F6;
      }

      .divide-line {
        height: 100%;
        border: 1px solid #6517F6;
        width: 0.5px;
      }
    }

    &.theme-purple {
      .color-theme {
        color: #6E43C4;
      }

      &.showBackgroundColor .bg-theme {
        background-color: #E2D9F3 !important;
      }

      .bg-theme-dark {
        background-color: #6E43C4 !important;
      }

      .border-theme {
        border: 1px solid #6E43C4;
      }

      .table-border-theme table td, .border-theme-top {
        border-top: 1px solid #6E43C4 !important;
      }

      .border-bottom-dashed-theme {
        width: 100%;
        margin-top: 4px;
        border-bottom: 2px dashed #6E43C4;
      }

      .divide-line {
        height: 100%;
        border: 1px solid #6E43C4;
        width: 0.5px;
      }
    }

    &.theme-orange {
      .color-theme {
        color: #F47D00;
      }

      &.showBackgroundColor .bg-theme {
        background-color: #FDE5CC !important;
      }

      .bg-theme-dark {
        background-color: #F47D00 !important;
      }

      .border-theme {
        border: 1px solid #F47D00;
      }

      .table-border-theme table td, .border-theme-top {
        border-top: 1px solid #F47D00 !important;
      }

      .border-bottom-dashed-theme {
        width: 100%;
        margin-top: 4px;
        border-bottom: 2px dashed #F47D00;
      }

      .divide-line {
        height: 100%;
        border: 1px solid #F47D00;
        width: 0.5px;
      }
    }

    &.theme-teal {
      .color-theme {
        color: #48C995;
      }

      &.showBackgroundColor .bg-theme {
        background-color: #DAF4EA !important;
      }

      .bg-theme-dark {
        background-color: #48C995 !important;
      }

      .border-theme {
        border: 1px solid #48C995;
      }

      .table-border-theme table td, .border-theme-top {
        border-top: 1px solid #48C995 !important;
      }

      .border-bottom-dashed-theme {
        width: 100%;
        margin-top: 4px;
        border-bottom: 2px dashed #48C995;
      }

      .divide-line {
        height: 100%;
        border: 1px solid #48C995;
        width: 0.5px;
      }
    }

    &.theme-gray {
      .color-theme {
        color: #78808A;
      }

      &.showBackgroundColor .bg-theme {
        background-color: #E4E6E8 !important;
      }

      .bg-theme-dark {
        background-color: #78808A !important;
      }

      .border-theme {
        border: 1px solid #78808A;
      }

      .table-border-theme table td, .border-theme-top {
        border-top: 1px solid #78808A !important;
      }

      .border-bottom-dashed-theme {
        width: 100%;
        margin-top: 4px;
        border-bottom: 2px dashed #78808A;
      }

      .divide-line {
        height: 100%;
        border: 1px solid #78808A;
        width: 0.5px;
      }
    }

    .text-gray {
      color: #000000;
    }

    .border-gray {
      border-color: #747783;
    }

    .text-black {
      color: #000;
    }

    .text-red {
      color: #FF0000;
    }

    .flex {
      display: flex;
    }

    .section-agency {
      display: flex;
      padding: 20px;
      gap: 40px;
      // border-radius: 12px;
      // border: 1px solid #E6E6E6;
    }

    .section-flight {
      border: 1px solid #E6E6E6
    }

    .segment-content {
      display: flex;
      align-items: center;
      position: relative;
      border-radius: 12px;
      border: 1px solid transparent;
    }

    .segment-content-l {
      // width: 55%;
      min-width: 560px;
      padding: 20px;
      z-index: 1;
      flex: 1;
    }

    .barcode-wrapper {
      display: flex;
      padding: 4px;
      align-items: center;
      align-self: stretch;
      flex-grow: 0;
      flex-shrink: 0;
      background: #fff;

      >img {
        width: 26px;
      }
    }

    .custom-v-line {
      border-left: 4px dashed white;
      margin-left: 8px;
    }

    .segment-content-r {
      max-width: 35%;
      flex-grow: 1;
      padding: 20px;
      z-index: 1;

      >.airline {
        >img.logo {
          height: 44px;
        }
      }
    }

    .group-class {
      font-family: Georgia, 'Times New Roman', Times, serif;
      font-size: 18px;
      letter-spacing: 1px;
      color: #305372;
    }

    .segment-booking-code {
      min-height: 67px;
      border-radius: 8px;
      overflow: hidden;

      >svg {
        top: 0;
        left: 0;
      }

      >.payment-status {
        right: -30px;
        width: 120px;
        padding: 4px 25px 3px;
        font-weight: 700;
        font-size: 8px;
        line-height: 1.2;
        text-align: center;
        text-transform: uppercase;
        transform: rotate(50deg);

        &.bg-current {
          background-color: currentColor;
        }
      }
    }

    .border-dot {
      border-bottom: 2px dashed;
    }

    .section-table {
      display: grid;
      border: 1px solid #E6E6E6;
      border-radius: 10px;
      overflow: hidden;

      &.pax-table-template-columns {
        grid-template-columns: 10% 40% 50%;
        &.show-ticket-number-grid {
          grid-template-columns: 5% 35% 20% 40%;
        }
      }

      &.price-table-template-columns {
        grid-template-columns: 1fr 1fr 1fr;
      }

      .border-t {
        border-top: 1px solid #E6E6E6;
      }

      &.table-responsive {
        border-top: 0px;
        border-bottom: 0px;

        tbody {
          border-left: 0px !important;
          border-right: 0px !important;
        }
      }
    }

    .pax-button {
      opacity: 0;
    }

    .section-flight:hover .pax-button,
    .pax-table-template-columns:hover .pax-button {
      opacity: 1;
    }

    .section-note {
      padding: 20px;
      border: 1px solid #E6E6E6;
      border-radius: 8px;
      display: flex;
      gap: 12px;
      align-items: flex-start;
    }

    .p-4px {
      padding: 4px;
    }

    .px-12px {
      padding-left: 12px;
      padding-right: 12px;
    }

    .px-20px {
      padding-left: 20px;
      padding-right: 20px;
    }

    .p-4px-8px {
      padding: 4px 8px !important;
    }

    .p-4px-16px {
      padding: 4px 16px;
    }

    .p-8px-16px {
      padding: 8px 16px;
    }

    .mt-4px {
      margin-top: 4px;
    }

    .my-12px {
      margin-top: 12px;
      margin-bottom: 12px;
    }

    .fw-700 {
      font-weight: 700;
    }

    .group {
      .plain {
        color: transparent;

        &:focus,
        &:hover,
        &:active {
          color: initial;

          +.formatter {
            display: none;
          }
        }
      }

      .formatter {
        top: 50%;
        transform: translateY(-50%);
        padding-left: 1em;
      }

      &:hover {
        .plain {
          color: inherit;
        }

        .formatter {
          display: none;
        }
      }
    }

    p,
    h6,
    h5,
    h4,
    h3,
    h2,
    h1 {
      margin: 0px;
      padding: 0px;
      line-height: unset;
    }

    .ql-editor {
      overflow: visible;
      padding: 0px !important;

      >* {
        line-height: unset;
      }
    }

    .ql-container {
      font-size: 16px
    }

    .ql-tooltip {
      z-index: 10000;
    }

    .table thead th {
      @extend .bg-theme;
      background-color: white !important;
    }

    #div-value-money {
      display: inline-flex;
      align-items: center;
      margin-bottom: -4px;
      width: 160px;
    }

    #value-money {
      display: inline;
      background-color: transparent;
      border: none;
      width: 100%;
    }
  }

  @import '@core/scss/vue/libs/quill.scss';
</style>
